import React from 'react'

function FrokDisplayHtml ({ editable, subtext, value }) {
  return (
    <div className='input__container input__container--html'>
      <div className='input__control'>
        {value}
        {editable && subtext}
      </div>
    </div>
  )
}

export default FrokDisplayHtml
