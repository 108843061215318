import React from 'react'

const FrokSelect = ({ label, name, id, value, onChange, options, placeholder = 'Please select...' }) => {

  const renderOptions = () => {
    return Object.keys(options).map(key => (
      <option value={key} key={key}>
        {options[key]}
      </option>
    ))
  }

  return (
    <div className="a-dropdown">
      {label && <label htmlFor={id}>{label}</label>}
      <select name={name} id={id} value={value} onChange={onChange}>
        <option value="">{placeholder}</option>
        {renderOptions()}
      </select>
    </div>
  )
}

export default FrokSelect
