import React, { Fragment } from 'react'
import registerBenchmarkAccount from '../network/requests/registerBenchmarkAccount'
import { showAlert } from '../utils/alert'

export class TermsButtons extends React.Component {

  handleClick = async () => {
    try {
      await registerBenchmarkAccount()
      window.close()
    } catch (error) {
      const errors = error.responseJSON.errors.detail.split('\n')
      let errorMessage = ''
      errors.forEach(function (error) {
        errorMessage += '<span class="__error_message">' + error + '</span>'
      })
      showAlert(errorMessage)
    }
  }

  render () {
    const { validCompany } = this.props

    return (
      <Fragment>
        <button type="button" className="a-button a-button--primary -without-icon" onClick={this.handleClick} disabled={!validCompany}>
          <span className="a-button__label">Agree and create my account</span>
        </button>&nbsp;
        <button type="button" className="a-button a-button--secondary -without-icon" onClick={window.close}>
          <span className="a-button__label">Decline and cancel account creation</span>
        </button>
      </Fragment>
    )
  }
}

export default TermsButtons
