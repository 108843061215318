import React from 'react'

export default function QuickLinkModal ({onSelect, availableQuickLinks, cancel, reason}) {
  let title = ''
  let subtitle = ''

  switch (reason) {
    case 'unavailable':
      title = 'This quick link is unavailable to you'
      subtitle = 'As you have been realigned, this quick link is now unavailable to you. Please remove or replace it with a new quick link'
      break
    case 'add':
      title = 'Add a quick link'
      subtitle = 'Choose one of the quick links to add'
      break
    case 'replace':
      title = 'Replace your quick link'
      subtitle = 'Choose one of the quick links to replace your selected tile'
      break
  }

  return (
    <div className="a-box--modal -show">
      <dialog open className="m-dialog -floating-shadow-s">
        <div className="m-dialog__content">
          <h2
            className="-size-2xl"
            id="dialog-default-dialog-without-header-title"
          >
            {title}
          </h2>
          <p
            className="m-dialog__body"
            id="dialog-default-dialog-without-header-description"
          >
            {subtitle}
          </p>
          <div className={'quick-links__links -two-col'}>
            {availableQuickLinks && availableQuickLinks.map((quickLink) => (
              <div key={quickLink.id} className='quick-links__item' onClick={() => onSelect(quickLink)}>
                <button type="button" className="a-button a-button--secondary">
                  <span style={{backgroundImage: 'url(' + quickLink.attributes.image + ')'}} className='quick-links__icon' title={quickLink.attributes.name}></span>
                  <span className="a-button__label">{quickLink.attributes.name}</span>
                </button>
              </div>
            ))}
          </div>
          <div className="m-dialog__actions">
            <button
              type="button"
              className="a-button a-button--secondary -without-icon"
              data-frok-action="cancel"
            >
              <span className="a-button__label" onClick={cancel}>Cancel</span>
            </button>
          </div>
        </div>
      </dialog>
    </div>
  )
}
