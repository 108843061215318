import React from 'react'

function FrokText ({ name, onChange, value, editable, error, disabled, placeholder }) {
  if (!editable) return value
  return (
    <div className="m-form-field">
        <div className="a-text-field">
            <input
                type="email"
                onChange={e => onChange(name, e.target.value)}
                id={name}
                value={value === null ? '' : value}
                disabled={disabled}
                placeholder={placeholder === null ? '' : placeholder} />
        </div>
        {error ? (
        <div className="a-notification a-notification--text -error" role="alert">
            <div className="a-notification__content">{error}</div>
        </div>
        ) : (
            ''
        )}
    </div>
  )
}

export default FrokText
