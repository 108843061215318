/**
 * Inlined from here because they don't offer an es5 build
 * https://github.com/sindresorhus/array-move
 */
const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to
  const item = array.splice(from, 1)[0]
  array.splice(startIndex, 0, item)
}

const arrayMove = (array, from, to) => {
  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}

export default arrayMove
