import uuid from 'uuid/v4'
import { pick } from 'lodash'

const preferencesFields = [
  'verticalFlue',
  'tmgui',
  'credit',
  'conventionalAndSystem',
  'travelDistance',
  'surveyFuelTypes',
  'installationFuelTypes',
  'systemBoilerInstallations',
]

// convert the flat form data into a shape the presenter will recognise
export default function fastTrackPreferencesFormatter (data) {
  return {
    id: uuid(),
    fastTrackPreferences: {
      id: uuid(),
      ...pick(data, preferencesFields),
    },
  }
}
