import Presenter from '../../../shared/network/presenters/Presenter'
import { AddressPresenter } from './AddressPresenter'
import { FilePresenter } from './FilePresenter'

export class ProfilePresenter extends Presenter {
  relationships () {
    return {
      address: AddressPresenter,
      logo: FilePresenter
    }
  }
}

ProfilePresenter.prototype.type = 'profile'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(ProfilePresenter, Presenter)

export default data => ProfilePresenter.render(data)
