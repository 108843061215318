import React from 'react'
import getChartColors from '../utils/getChartColors'
import LineChart from './LineChart'

const COLORS = ['#b12ea9']

const ProfileViewsChart = (props = {}) => {
  const { chart, link } = props
  const labelsLength = chart.labels.length

  const formatLineDatasets = datasets =>
    datasets.map((dataset, ix) => ({
      ...dataset,
      backgroundColor: getChartColors(labelsLength, COLORS[ix]),
      borderColor: getChartColors(labelsLength, COLORS[ix]),
      borderWidth: 2,
    }))

  const config = {
    type: 'line',
    data: {
      labels: chart.labels,
      datasets: formatLineDatasets(chart.datasets),
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          offset: true,
          title: {
            text: chart.xAxisText,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
          ticks: {
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: chart.yAxisText,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          ticks: {
            display: false,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
          font: {
            color: '#a4a4a4'
          },
        },
      },
      plugins: {
        legend: {
          display: false,
          color: '#2e3033',
          labels: {
            font: {
              size: 18,
              family: "'boschsans'",
              weight: 'bold'
            },
          }
        },
        tooltip: {
          backgroundColor: '#ffffff',
          borderColor: '#f5f5f5',
          borderWidth: 1,
          titleColor: '#000000',
          padding: 10,
          displayColors: false,
          caretSize: 0,
          cornerRadius: 0,
          bodyColor: '#ffffff',
          bodyFont: {
            size: 18,
          },
          callbacks: {
            title: function () { return '' },
            labelTextColor: function (context) {
              return context.dataset.backgroundColor[0]
            },
            label: function (context) {
              return `${context.formattedValue} views`
            }
          }
        }
      }
    }
  }

  return (
    <>
      <h3 className="m-t-0">Profile views</h3>
      <div className="m-box">
        <div className="e-flex -column">
          <LineChart config={config} />
          <div style={styles.button} className="a-link a-link--button">
            <a aria-label="Primary button link label" href={link}>
              <span>Update my profile</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const styles = {
  button: {
    margin: '0 auto'
  }
}

export default ProfileViewsChart
