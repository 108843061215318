import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

const Tile = ({ id, name, image, notificationCount, mandatory, edit, onRemove, link, unavailable }) => {
  return (
    <>
      <div key={id} className='quick-links__item'>
        <a href={edit || unavailable ? null : link} type="button" className={`a-button a-button--secondary ${edit && !mandatory ? 'is-open' : ''} ${(edit && mandatory) || unavailable ? 'is-unavailable' : ''}`}>
          <span style={{backgroundImage: 'url(' + image + ')'}} className='quick-links__icon' title={name}></span>
          <span className='a-button__label'>{name}</span>
          {edit && !mandatory
            ? (<div className="a-badge a-badge--remove" tabIndex="0" role="button" onMouseDown={() => onRemove(id)} aria-live="polite">
              <i className="a-icon ui-ic-close"></i>
            </div>)
            : notificationCount > 0 && (<div className="a-badge" tabIndex="0" role="status" aria-live="polite">{notificationCount}</div>)
          }
        </a>
      </div>
    </>
  )
}

const SortableTile = SortableElement(Tile)

const QuickLinkTile = ({ index, value, onRemove, edit }) => {
  const Component = !value.isMandatory && edit ? SortableTile : Tile
  return <Component
    index={index}
    onRemove={onRemove}
    mandatory={value.isMandatory}
    edit={edit}
    notificationCount={value.todoCount}
    {...value}
  />
}

export default QuickLinkTile
