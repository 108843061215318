import React, { useState, Children } from 'react'
import Modal from '../../../lifestyle/components/Modal'

function OnboardingModal ({ children, onComplete, acceptTerms, declineTerms, name, setErrors, errors, acceptedTerms }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isOpen, setIsOpen] = useState(true)
  const content = Children.toArray(children)
  const slide = content[currentSlide]
  const isTerms = () => (content[currentSlide] && content[currentSlide].props.isTerms) || false

  const completeLater = () => {
    declineTerms()
    onComplete()
    setIsOpen(false)
  }

  const nextSlide = () => {
    if (isTerms()) {
      let tmpErrors = {}
      tmpErrors['name'] = name === ''

      Object.keys(acceptedTerms).forEach(key => {
        tmpErrors[key] = !acceptedTerms[key]
      })
      setErrors({...errors, ...tmpErrors})

      if (Object.values(tmpErrors).filter(error => error).length) return

      acceptTerms({name})
    }

    if (currentSlide === content.length - 1) {
      setIsOpen(false)
      onComplete()
    }

    setCurrentSlide(currentSlide + 1)
  }

  const btnText = () => {
    if (isTerms()) {
      return 'Accept'
    }

    return currentSlide === content.length - 1 ? 'Finish' : 'Next'
  }

  return (
    <Modal isOpen={isOpen} displayHeader={false}>
      <div className="onboarding__container--modal">
        {slide}
        <div className="onboarding__container--pagination">
          {content.map((_, i) => (
            <span
              key={i}
              className={'onboarding__pagination--dot' + (i === currentSlide ? ' is-active' : '')}
              onClick={() => setCurrentSlide(i)}
            />
          ))}
        </div>
        <div className={'onboarding__container' + (isTerms() ? '--terms' : '--button onboarding__buttons')}>
          {isTerms() && (
            <button className="a-button a-button--secondary -without-icon onboarding__button--decline" onClick={completeLater}><span className="a-button__label">Complete Later</span></button>
          )}
          <button className="a-button a-button--primary -without-icon onboarding__button--next" onClick={nextSlide}>
            <span className="a-button__label">{btnText()}</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default OnboardingModal
