import React from 'react'
import classNames from 'classnames'

function FrokImageCheckbox ({ name, onChange, value, options, editable, error }) {
  const toggleItem = item => {
    if (value.includes(item)) {
      onChange(
        name,
        value.filter(currItem => currItem !== item),
      )
    } else {
      onChange(name, [...value, item])
    }
  }
  return (
    <span className='ImageCheckboxList'>
      <ul>
        {options.map(({ id, name, image }) => (
          <li
            key={id}
            onClick={() => editable && toggleItem(id)}
            className={classNames(value.includes(id) && 'selected', editable && 'editable', error && 'error')}
          >
            <img src={image} />
            <p>{name}</p>
          </li>
        ))}
      </ul>
    </span>
  )
}

export default FrokImageCheckbox
