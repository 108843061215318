import React from 'react'
import useToggleScroll from '../../../assets/react/shared/hooks/useToggleScroll'

const DisconnectAccountModal = ({ onConfirm, onCancel }) => {
  const { enableScroll } = useToggleScroll(document.body, true)

  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className='modal__title'>Disconnect your Benchmark account</div>
        <div className='modal__body'>
          <p>
            Disconnecting your Benchmark and loyalty accounts will mean you can no longer submit Benchmark Checklists
            through the Worcester Bosch website or MyWorcester App. You can always reconnect your accounts later by
            returning to the My Applications area of your loyalty portal.
          </p>
          <p>Please note that your Benchmark account will not be affected or deleted.</p>
        </div>
        <div className='modal__buttons'>
          <button type="button" className="a-button a-button--secondary -without-icon" onClick={() => {
            enableScroll()
            onCancel()
          }}>
            <span className="a-button__label">Cancel</span>
          </button>
          <button type="button" className="a-button a-button--primary -without-icon" onClick={() => {
            enableScroll()
            onConfirm()
          }}>
            <span className="a-button__label">Continue and disconnect</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DisconnectAccountModal
