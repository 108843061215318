import React, { Fragment } from 'react'
import FilePicker from '../../../components/FilePicker'
import Notification from '../../../../notification'
import classnames from 'classnames'

export default function ImageUpload (props) {
  const { extensions, onError, onChange, error, errorLabel, filename } = props
  const containerClasses = classnames('a-file-upload-input form-group', {
    'has-error': error !== undefined,
  })
  return (
    <Fragment>
      <div className={containerClasses}>
        <FilePicker
          extensions={extensions}
          onChange={onChange}
          onError={onError}
        >
          <label htmlFor="file-upload-input-1">
            <i className="a-icon boschicon-bosch-ic-upload" title="upload icon"></i>
            Choose file
          </label>
        </FilePicker>

        <div className="a-file-upload-input__preview"><p className="m-bl-0">{filename || 'No file chosen'}</p></div>
      </div>
      <p className='m-b-0'>Accepted formats: JPG, PNG. Maximum file size: 5MB</p>

      {errorLabel && (
        <div className="notification-wrapper"><Notification classnames='margin-reversed' type="error" dismissible={false} text={errorLabel} icon="ui-ic-alert-error" /></div>
      )}
    </Fragment>
  )
}

ImageUpload.defaultProps = {
  extensions: ['jpg', 'jpeg', 'png']
}
