import React, { Fragment, useState } from 'react'
import { addressToArray } from '../../../util/addressToString'
import FrokCapturePlusWithFallback from '../../FrokCapturePlusWithFallback'

function FrokAddressWithRegistered ({ name, onChange, value, options, editable, error }) {
  const { isUsingRegistered, registeredAddressId } = options
  const [usingRegistered, setUsingRegistered] = useState(isUsingRegistered)
  const [address, setAddress] = useState(value)

  if (!editable) {
    if (usingRegistered) return 'Using registered address'
    return addressToArray(value).map((string, ix) => (
      <Fragment key={ix}>
        {string}<br />
      </Fragment>
    ))
  }

  const handleChangeRegistered = (e) => {
    const registeredValue = e.target.checked
    setUsingRegistered(registeredValue)

    if (registeredValue === true) {
      // the new address is just the id
      onChange(name, registeredAddressId)
    } else {
      // back to the last known address value
      onChange(name, address)
    }
  }

  // we update both the outer state and our inner copy in case the user unticks the checkox and we need to restore the original address
  const handleChangeAddress = updatedAddress => {
    setAddress(updatedAddress)
    onChange(name, updatedAddress)
  }

  return (
    <div className="e-flex -column e-flex-gap-xs">
      <div className="m-form-field m-form-field--checkbox">
        <div className="a-checkbox">
          <input type="checkbox" id="checkbox_use_reg_address" checked={usingRegistered} onChange={handleChangeRegistered} />
          <label htmlFor="checkbox_use_reg_address">Use registered address{' '}</label>
        </div>
      </div>
      {!usingRegistered && (
        <FrokCapturePlusWithFallback address={address} onChange={handleChangeAddress} />
      )}
    </div>
  )
}

export default FrokAddressWithRegistered
