import React from 'react'

export default function OnboardingSlide ({ image, title, body }) {
  return (
    <div className='onboarding__slide'>
      <img className="onboarding__image" src={image} />
      <div className="onboarding__content">
        <p role="heading" className='onboarding__title'>{title}</p>
        <p className="onboarding__text">{body}</p>
      </div>
    </div>
  )
}
