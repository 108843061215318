import { useEffect } from 'react'

const useToggleScroll = (element, disableOnLoad = false) => {
  const disableScroll = () => (element.style.overflow = 'hidden')
  const enableScroll = () => (element.style.overflow = 'auto')

  useEffect(() => {
    if (disableOnLoad) {
      disableScroll()
    }
  }, [])

  return {
    disableScroll,
    enableScroll,
  }
}

export default useToggleScroll
