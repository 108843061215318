import React from 'react'
import { unixTimestampFormatDate } from '../../utils/unixTimestampFormatDate'
import { LoadingSpinner, ErrorMessage, EmptyResult } from './Common'
import { capitalize } from 'lodash'
import usePagination from '../../hooks/usePagination'
import { renderPagination } from '../../utils/renderPagination'
import Filter from './Filter'

const Completed = ({ productCategories }) => {
  const queryParams = {
    filter: {
      order: 'desc',
      submittedByEngineer: true,
      getSubmitted: true,
      withDraft: true
    },
    include: [
      'customer',
      'customer.address',
    ].join(','),
  }

  const { guarantees, isLoading, error, setError, currentPage, totalPages, handlePageChange, refreshGuarantees } = usePagination(queryParams)

  const getFullName = (object) => {
    const { title, firstName, lastName } = object || {}

    return [title, firstName, lastName].filter(Boolean).join(' ')
  }

  const getFullAddress = (guarantee) => {
    const { unitNumber, buildingName, houseNumber, street, district, city, postcode } = guarantee.customer?.address || {}

    return [unitNumber, buildingName, houseNumber, street, district, city, postcode]
      .filter(Boolean)
      .join(', ')
  }

  const renderBody = () => {
    if (isLoading) {
      return <LoadingSpinner />
    }

    if (error) {
      return <ErrorMessage message={error} />
    }

    if (guarantees.length === 0) {
      return <EmptyResult />
    }

    return guarantees.map((guarantee, index) => (
      <tr key={index}>
        <td>
          {unixTimestampFormatDate(guarantee.installationDate)}<br />
          {unixTimestampFormatDate(guarantee.submittedAt) ?? unixTimestampFormatDate(guarantee.createdAt)}
        </td>
        <td>{getFullName(guarantee.customer)}</td>
        <td>{getFullAddress(guarantee)}</td>
        <td>
          {['draft', 'evidence_draft', 'requires_verification'].includes(guarantee.status)
            ? 'Waiting approval'
            : ['awaiting_verification'].includes(guarantee.status)
              ? 'Waiting Admin Approval'
              : capitalize(guarantee.status_label)}
        </td>

      </tr>
    ))
  }

  const handleFilterChange = async (filters) => {
    try {
      queryParams.filter.installationStartDate = filters.startDate
      queryParams.filter.installationEndDate = filters.endDate
      queryParams.filter.customer = getFullName(filters)
      queryParams.filter.postcode = filters.postcode
      if (filters.productCategory) {
        queryParams.filter.productType = filters.productCategory
      }

      await refreshGuarantees()
    } catch (err) {
      setError(err.message)
    }
  }

  const shouldRenderPagination = guarantees.length > 0 && totalPages > 1

  return (
    <div className="completed-guarantees-container p-t-40 p-b-40">
      <h2 className="-size-2xl">Completed registrations</h2>
      <Filter
        productCategories={productCategories}
        onFilterChange={handleFilterChange}
      />
      <div className="table__overflow">
        <div id="incomplete-table">
          <table className="m-table" aria-label="Highlights">
            <thead>
              <tr>
                <th width="160">Installed & Submitted</th>
                <th>Customer</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
          </table>
        </div>
      </div>
      {shouldRenderPagination && renderPagination(currentPage, totalPages, handlePageChange)}
    </div>
  )
}

export default Completed
