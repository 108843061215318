import React from 'react'

function FrokCheckboxYesNo ({ name, onChange, value, options, editable, error }) {
  if (!editable) {
    return value ? (
      <React.Fragment>Yes</React.Fragment>
    ) : (
      <React.Fragment>No</React.Fragment>
    )
  }

  return (
    <div className="a-checkbox">
      <input
        id={name}
        name={name}
        type="checkbox"
        onChange={() => onChange(name, !value)}
        checked={value}
      />
      <label htmlFor={name}>
        &nbsp;
      </label>
      <span className='input__validation'>{error}</span>
    </div>
  )
}

export default FrokCheckboxYesNo
