import React, { useEffect, useState } from 'react'
import { isGuaranteeEditable } from '../../utils/checkGuaranteeStatus'

const GuaranteeActionsMenu = ({ guarantee, index, onDelete }) => {
  const [menuIndex, setMenuIndex] = useState(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuIndex !== null && !event.target.closest('.button__action')) {
        setMenuIndex(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuIndex])

  const toggleActions = (index) => {
    setMenuIndex(prevIndex => (prevIndex === index ? null : index))
  }

  return (
    <div
      role="link"
      title="View all details for this guarantee"
      className={`button__secondary button__action ${menuIndex === index ? 'is-open' : ''}`}
      onClick={() => toggleActions(index)}
    >
      <span className="button__action--trigger">Actions</span>
      <ul className="button__action--list">
        <li className="button__action--item">
          <a
            className="button__action--link"
            href={`/jobs/guarantees/${guarantee.id}`}
            data-analytics
            data-analytics-category="guarantees"
            data-analytics-action="View Guarantees"
            data-analytics-label="View Guarantees"
            data-analytics-value="View Guarantees"
          >
            View
          </a>
        </li>
        {isGuaranteeEditable(guarantee.status) && (
          <li className="button__action--item">
            <a
              className="button__action--link"
              href={`/guarantees/${guarantee.id}/installation-details`}
              data-analytics
              data-analytics-category="guarantees-edit"
              data-analytics-action="Edit Guarantees"
              data-analytics-label="Guarantees Edit"
              data-analytics-value="Guarantees Edit"
            >
              Edit
            </a>
          </li>
        )}
        <li className=" button__action--item">
          <button
            className="button__action--link"
            onClick={onDelete}
            data-analytics
            data-analytics-category="guarantees-delete"
            data-analytics-action="Delete Guarantees"
            data-analytics-label="Guarantees Delete"
            data-analytics-value="Guarantees Delete"
          >
            Delete
          </button>
        </li>
      </ul>
    </div>
  )
}

export default GuaranteeActionsMenu
