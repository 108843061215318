import React, { useState, useMemo } from 'react'
import FrokPasswordModal from './FrokPasswordModal'
import createSchema from './createSchema'
import submitAccordionForm from '../../network/requests/submitAccordionForm'
import transformError from '../../../fast-track/validation/transformError'
import { getPasswordError, getUserFriendlyErrorMessage, getValidationErrorMap } from '../../util/getErrors'
import FrokFormRow from '../../../shared/components/FrokFormRow/FrokFormRow'
import flattenFields from '../../../shared/util/flattenFields'

export default function FrokAccordionForm ({ form: { fields, name, requiresPassword, isSingleKeyUser, singleKeyUrls } }) {
  const initialState = useMemo(() => flattenFields(fields), [fields])
  const schema = useMemo(() => createSchema(fields), [fields])
  const [editable, setEditable] = useState(false)
  const [values, setValues] = useState(initialState)
  const [fieldErrors, setFieldErrors] = useState({})
  const [remoteError, setRemoteError] = useState(false)
  const [requestPassword, setRequestPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const makeRequest = () => {
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {
        if (valid) {
          setFieldErrors({})
          validatePassword()
        }
      })
      .catch(error => {
        const errors = transformError(error)
        // console.warn(error, errors)
        setFieldErrors(errors)
      })
  }

  const validatePassword = () => {
    if (requiresPassword) {
      setRequestPassword(true)
    } else {
      postData(values)
    }
  }

  const postData = data => {
    setLoading(true)
    setRemoteError(false)
    submitAccordionForm(name, data)
      .then(() => {
        setRequestPassword(false)
        setEditable(false)
      })
      .catch(err => {
        const passwordError = getPasswordError(err)
        if (requiresPassword && passwordError) {
          setRemoteError(passwordError)
          setRequestPassword(true)
        } else {
          const errorMap = getValidationErrorMap(err)
          if (errorMap) {
            setFieldErrors(errorMap)
          } else {
            const message = getUserFriendlyErrorMessage(err)
            setRemoteError(message)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleToggleEdit = () => {
    if (editable) {
      // reset to initial state
      setValues(initialState)
    }
    setEditable(!editable)
  }

  const handleOnChange = (name, value) => setValues({ ...values, [name]: value })

  const ButtonLink = ({ singleKeyUrl, children }) => {
    const handleClick = () => {
      window.open(singleKeyUrl, '_blank', 'noopener,noreferrer')
    }

    return (
      <button className="a-button a-button--primary -without-icon" style={{ float: 'left' }} onClick={handleClick}>
        {children}
      </button>
    )
  }

  const renderRow = (child) => (
    <FrokFormRow
      key={child.name}
      {...child}
      error={fieldErrors[child.name]}
      editable={editable}
      onChange={handleOnChange}
      value={values[child.name]}
    />
  )

  const renderEmailRow = child =>
    renderRow({
      ...child,
      subtext: (
        <>
          <p>Your email address is now managed by SingleKey ID, a Bosch managed service that will enable you to login to any Bosch platform using one email address.</p>
          <p>To change your email address click on Manage my SingleKey ID below.</p>
          <ButtonLink singleKeyUrl={singleKeyUrls.changeEmailUrl}>
            <span className="a-button__label">Manage SingleKey ID</span>
          </ButtonLink>
        </>
      ),
    })

  const renderPasswordRow = () => (
    <tr>
      <td colSpan="100%">
        <p>Your email address and password are now both managed by SingleKey ID, a Bosch managed service that will enable you to login to any Bosch platform using one email address.</p>
        <p>To change your password click on the button below. Please be aware that changing your password will also apply to any other services you log into using your SingleKey ID.</p>
        <ButtonLink singleKeyUrl={singleKeyUrls.changePasswordUrl}>
          <span className="a-button__label">Manage SingleKey ID</span>
        </ButtonLink>
      </td>
    </tr>
  )

  const isSingleKeyPasswordForm = name === 'my-password' && isSingleKeyUser

  return (
    <>
      {
        !isSingleKeyPasswordForm && (
          <button className="a-button a-button--secondary -without-icon" onClick={handleToggleEdit}>
            <span className="a-button__label">{editable ? 'Cancel' : 'Edit details'}</span>
          </button>
        )
      }
      <table className="m-table">
        <tbody>
          {isSingleKeyPasswordForm ? renderPasswordRow() : fields.map(child => {
            if (child.name === 'emailAddress' && child.type === 'html') return renderEmailRow(child)
            return renderRow(child)
          })}
        </tbody>
      </table>
      {!requestPassword && remoteError && <p className='error-text'>{remoteError}</p>}
      {editable && (
        <div className="a-accordion__form-save">
          <button onClick={makeRequest} className='a-button a-button--secondary -without-icon'>
            <span className="a-button__label">Save your changes</span>
          </button>
        </div>
      )}
      {requestPassword && (
        <FrokPasswordModal
          onSubmit={password => postData({ ...values, currentPassword: password })}
          hide={() => setRequestPassword(false)}
          error={remoteError}
          loading={loading}
        />
      )}
    </>
  )
}
