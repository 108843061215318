import React from 'react'
import FrokDisplayHtml from './FrokFormRowFields/FrokDisplayHtml'
import FrokText from './FrokFormRowFields/FrokText'
import FrokEmail from './FrokFormRowFields/FrokEmail'
import FrokURL from './FrokFormRowFields/FrokURL'
import FrokTextArea from './FrokFormRowFields/FrokTextArea'
import FrokNumberInput from './FrokFormRowFields/FrokNumber'
import FrokToggle from './FrokFormRowFields/FrokToggle'
import FrokList from './FrokFormRowFields/FrokList'
import FrokImageCheckbox from './FrokFormRowFields/FrokImageCheckbox'
import FrokCheckboxYesNo from './FrokFormRowFields/FrokCheckboxYesNo'
import FrokImage from './FrokFormRowFields/FrokImage'
import FrokAddressWithRegistered from './FrokFormRowFields/FrokAddressWithRegistered'
import FrokAddress from './FrokFormRowFields/FrokAddress'
import FrokHeading from './FrokFormRowFields/FrokHeading'
import FrokCheckboxCards from './FrokFormRowFields/FrokCheckboxCards'
import FrokWYSIWYG from './FrokFormRowFields/FrokWYSIWYG'
import FrokDateInput from './FrokFormRowFields/FrokDateInput'
import FrokSelect from './FrokFormRowFields/FrokSelect'
import FrokRadioButton from './FrokFormRowFields/FrokRadioButton'

const fields = {
  addressWithRegistered: FrokAddressWithRegistered,
  address: FrokAddress,
  boolean: FrokToggle,
  checkbox: FrokCheckboxYesNo,
  html: FrokDisplayHtml,
  radio: FrokRadioButton,
  checkboxcards: FrokCheckboxCards,
  date: FrokDateInput,
  heading: FrokHeading,
  imageCheckbox: FrokImageCheckbox,
  image: FrokImage,
  list: FrokList,
  number: FrokNumberInput,
  password: FrokText,
  select: FrokSelect,
  text: FrokText,
  email: FrokEmail,
  fixed: null,
  textarea: FrokTextArea,
  wysiwyg: FrokWYSIWYG,
  url: FrokURL
}

export default function FrokFormRow ({ label, type, ...props }) {
  const Component = fields[type]
  if (Component === undefined) {
    console.warn('Unknown component type:', type)
    return null
  }

  if (Component === null) {
    return null
  }

  if (Component === FrokHeading) {
    return <div className="details__row"><Component label={label} /></div>
  }

  const { helpText } = props
  return (

    <tr>
      <td width="30%">
        <label
          className="a-row-label"
          htmlFor={props.name}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {helpText && (
          <p className="details__subheader">{helpText}</p>
        )}
      </td>
      <td><Component {...props} /></td>
    </tr>
  )
}
