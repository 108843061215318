import React, { Fragment } from 'react'
import Incomplete from './Incomplete'
import Completed from './Completed'

const EngineerGuarantees = ({ incompleteOnly = false, productCategories = {} }) => {

  return (
    <Fragment>
      <Incomplete
        incompleteOnly={incompleteOnly}
      />
      {!incompleteOnly && <Completed productCategories={productCategories}/>}
    </Fragment>
  )
}

export default EngineerGuarantees
