import React, { useEffect, useRef } from 'react'

const LineChart = (props = {}) => {
  const { config, chartHeight = '360px' } = props
  const ref = useRef()
  const instance = useRef()

  const initialise = () => {
    const ctx = ref.current.getContext('2d')

    window.Chart.defaults.font.size = 16
    instance.current = new window.Chart(ctx, config)
  }

  useEffect(() => {
    if (!instance.current) {
      initialise()
    }
  }, [])

  const styles = {
    container: {
      position: 'relative',
      height: chartHeight,
      width: '100%',
      marginTop: 'auto',
    }
  }

  return (
    <div style={styles.container}>
      <canvas ref={ref} width='100%' height='100%'></canvas>
    </div>
  )
}

export default LineChart
