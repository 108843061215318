import api from '../../../shared/network/api'
import installerProfileFormatter from '../formatters/installerProfileFormatter'
import prepareCompany from '../presenters/CompanyPresenter'
import prepareUser from '../presenters/UserPresenter'
import companyDetailsFormatter from '../formatters/companyDetailsFormatter'
import fastTrackTermsFormatter from '../formatters/fastTrackTermsFormatter'
import notificationPreferencesFormatter from '../formatters/notificationPreferencesFormatter'
import preferencesFormatter from '../formatters/preferencesFormatter'
import fastTrackPreferences from '../formatters/fastTrackPreferencesFormatter'
import _ from 'lodash'

const presentersByRoute = {
  '/company': prepareCompany,
  '/loyalty/profile': prepareCompany,
  '/user': prepareUser,
  '/consent': data => ({ data: { attributes: _.omitBy(data, _.isNull) } }),
}

const formattersByForm = {
  'installer-profile': installerProfileFormatter,
  'company-details': companyDetailsFormatter,
  'fast-track-terms': fastTrackTermsFormatter,
  'notification-preferences': notificationPreferencesFormatter,
  'preferences': preferencesFormatter,
  'fast-track-preferences': fastTrackPreferences,
  'my-details': data => data,
  'my-password': data => data,
  'consents-form': data => data,
}

const routesByForm = {
  'installer-profile': '/loyalty/profile?include=profile',
  'company-details': '/company',
  'fast-track-terms': '/company',
  'notification-preferences': '/company',
  'preferences': '/company',
  'fast-track-preferences': '/company',
  'my-details': '/user',
  'my-password': '/user',
  'consents-form': '/consent',
}

export default function submitAccordionForm (name, data) {
  const route = routesByForm[name] || ''
  const formatter = formattersByForm[name]
  const routeWithoutParams = route.split('?')[0]
  const presenter = presentersByRoute[routeWithoutParams]

  if (presenter === undefined || route === undefined || formatter === undefined) {
    return Promise.reject(new Error(`Missing config for accordion form: "${name}"`))
  }
  const payload = presenter(formatter(data))

  return api.patch(route, payload)
}
