import uuid from 'uuid/v4'

// convert the flat form data into a shape the presenter will recognise
export default function fastTrackTermsFormatter (data) {
  return {
    id: uuid(),
    fastTrack: {
      id: uuid(),
      terms: data.terms
    }
  }
}
