import React, { useState } from 'react'
import cx from 'classnames'

function PasswordModal ({ onSubmit, hide, error, loading }) {
  const [password, setPassword] = useState('')
  return (
    <div className='modal'>
      <div className='modal__container'>
        <div className="modal__title">Enter Password</div>
        <div className="modal__body">
          <p>To confirm the changes you have made to your profile, please enter your password.</p>
          <div className={cx('input__container--text', error && 'has-error')}>
            <input
              name='secret'
              type='password'
              autoComplete='off'
              value={password}
              placeholder={'Enter your password'}
              onChange={e => setPassword(e.target.value)}
              className='input__control'
              disabled={loading}
            />
            {error && <span className='input__validation'>{error}</span>}
          </div>
        </div>
        <div className="modal__buttons">
          <button
            className='button__secondary modal__button'
            disabled={loading}
            onClick={() => {
              hide()
            }}
          >
            Cancel
          </button>
          <button
            className='button__primary modal__button'
            disabled={loading}
            onClick={() => {
              onSubmit(password)
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default PasswordModal
