import React from 'react'
import ReactSelect from 'react-select'

function FrokSelect ({ name, onChange, value, options, editable }) {
  if (!editable) {
    return value
  }

  return <ReactSelect
    name={name}
    className='a-select'
    classNamePrefix="a-select"
    defaultValue={{ value, label: value }}
    onChange={pickedOption => onChange(name, pickedOption.value)}
    options={Object.keys(options).map(option => {
      return {value: options[option], label: options[option]}
    })}
  />
}

export default FrokSelect
