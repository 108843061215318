import React from 'react'
import getChartColors from '../utils/getChartColors'
import LineChart from './LineChart'

const COLORS = ['#b12ea9', '#006ead', '#18837e']

const TotalRegistrationsChart = ({chart = { labels: [] }, style = {}} = {}) => {
  const { labels = [] } = chart
  const labelsLength = labels.length

  const {
    chartHeight = '260px',
    fontSize = 12,
    axisfontSize = 12,
    fontWeight = 'normal',
    labelPadding = 10,
  } = style

  const formatLineDatasets = datasets =>
    datasets.map((dataset, ix) => ({
      ...dataset,
      data: dataset.data.map((data, ix) => ({
        ...data,
        id: chart.labels[ix],
      })),
      backgroundColor: getChartColors(labelsLength, COLORS[ix]),
      borderColor: getChartColors(labelsLength, COLORS[ix]),
      borderWidth: 2,
    }))

  const config = {
    type: 'line',
    data: {
      labels: chart.labels,
      datasets: formatLineDatasets(chart.datasets),
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: 'id',
        yAxisKey: 'total'
      },
      scales: {
        x: {
          offset: true,
          display: true,
          title: {
            text: chart.xAxisText,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: fontWeight
            },
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
          ticks: {
            color: '#2e3033',
            font: {
              size: fontSize,
              family: "'boschsans'",
              weight: fontWeight
            },
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: chart.yAxisText,
            color: '#2e3033',
            font: {
              size: axisfontSize,
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          ticks: {
            color: '#2e3033',
            font: {
              size: fontSize,
              family: "'boschsans'",
              weight: 'normal'
            },
            stepSize: 10,
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            padding: labelPadding,
            boxWidth: 10,
            usePointStyle: true,
            color: '#2e3033',
            font: {
              size: axisfontSize,
              family: "'boschsans'",
              weight: 'bold'
            },
          },
        },
        tooltip: {
          backgroundColor: '#ffffff',
          borderColor: '#f5f5f5',
          borderWidth: 1,
          titleColor: '#000000',
          padding: 10,
          displayColors: false,
          caretSize: 0,
          cornerRadius: 0,
          bodyColor: '#ffffff',
          bodyFont: {
            size: fontSize,
          },
          callbacks: {
            title: function () { return '' },
            labelTextColor: function (context) {
              return context.dataset.backgroundColor[0]
            },
            label: function (context) {
              const { total } = context.raw
              return `Total: ${total}`
            }
          }
        }
      }
    }
  }

  return (
    <div className="e-flex -column">
      <LineChart config={config} chartHeight={chartHeight} />
    </div>
  )
}

export default TotalRegistrationsChart
