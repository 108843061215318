import React, { useState, useEffect } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { isEqual } from 'lodash'
import arrayMove from '../../util/array-move'
import ReplaceModal from './QuickLinkModal'
import QuickLinkTile from './QuickLinkTile'
import getQuickLinksRequest from '../../network/getQuickLinksRequest'
import getAvailableQuickLinksRequest from '../../network/getAvailableQuickLinksRequest'
import postAvailableQuickLinksRequest from '../../network/postAvailableQuickLinksRequest'

const TileGrid = SortableContainer(({ items, onRemove, onReplace, onAdd, edit, canAddTile }) => {
  return (
    <div className='quick-links__links -three-col'>
      {items.map((value, index) => (
        <QuickLinkTile
          key={value.id}
          index={index}
          onRemove={onRemove}
          onReplace={onReplace}
          value={{id: value.id, ...value.attributes}}
          edit={edit}
        />
      ))}
      {edit && canAddTile && (
        <div className="quick-links__item">
          <button type="button" onClick={onAdd} className="a-button a-button-dialog a-button__add-link">
            <i className="a-icon ui-ic-plus quick-links__icon"></i>
            <span className='a-button__label'>Add quick link</span>
          </button>
        </div>
      )}
    </div>
  )
})

const getIds = items => items.map(item => item.id)

const mandatoryFirst = links => links.sort((a, b) => {
  if (a.attributes.isMandatory && !b.attributes.isMandatory) return -1
  if (b.attributes.isMandatory && !a.attributes.isMandatory) return 1
  return 0
})

function QuickLinkGrid ({ isEngineer }) {
  const [quickLinks, setQuickLinks] = useState([])
  const [availableQuickLinks, setAvailableQuickLinks] = useState([])
  const [removedQuickLinks, setRemovedQuickLinks] = useState([])
  const [toReplaceId, setToReplaceId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [prevQuickLinks, setPrevQuickLinks] = useState(quickLinks)
  const [modalReason, setModalReason] = useState(null)
  useEffect(() => setPrevQuickLinks(quickLinks), [edit])

  useEffect(() => {
    getQuickLinksRequest().then(data => setQuickLinks(mandatoryFirst(data.data)))
    getAvailableQuickLinksRequest().then(data => setAvailableQuickLinks(data.data))
  }, [])

  const save = () => {
    const newIds = getIds(quickLinks)
    const oldIds = getIds(prevQuickLinks)
    if (!isEqual(newIds, oldIds)) {

      const newOrder = quickLinks
        .filter(quickLink => !quickLink.attributes.isMandatory)
        .map(({id}) => ({type: 'quick-link', id}))

      const forcedRemoved = removedQuickLinks
        .map(({id}) => ({type: 'removed-forced-quick-link', id}))

      const newState = {
        data: [...newOrder, ...forcedRemoved]
      }

      postAvailableQuickLinksRequest(newState)
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => quickLinks[oldIndex] && setQuickLinks(arrayMove(quickLinks, oldIndex, newIndex))

  const onRemove = id => {
    let quicklink = quickLinks.find(quickLink => quickLink.id === id)
    setAvailableQuickLinks([
      ...availableQuickLinks,
      quicklink
    ])
    setQuickLinks(quickLinks.filter(quickLink => quickLink && quickLink.id !== id))
    if (quicklink.attributes.isForced) {
      setRemovedQuickLinks([...removedQuickLinks, quicklink])
    }
  }

  const replace = (newQuicklink) => {
    setAvailableQuickLinks([
      ...availableQuickLinks.filter(
        quickLink => quickLink.id !== newQuicklink.id
      ),
      quickLinks.find(quickLink => quickLink.id === toReplaceId)
    ])
    setQuickLinks(quickLinks.map(quickLink =>
      quickLink.id === toReplaceId
        ? newQuicklink
        : quickLink
    ))
    setToReplaceId(null)
    setModalReason(null)
  }

  const onReplace = (replaceId, isUnavailable) => {
    setToReplaceId(replaceId)
    setModalReason(isUnavailable ? 'unavailable' : 'replace')
  }

  const add = (newQuicklink) => {
    setQuickLinks([...quickLinks, newQuicklink])
    setAvailableQuickLinks(
      availableQuickLinks.filter(
        quickLink => quickLink.id !== newQuicklink.id
      )
    )
    setModalReason(null)
  }

  const canAddTile = () => quickLinks.length < 12

  const onAdd = () => setModalReason('add')

  const closeModal = () => {
    setToReplaceId(null)
    setModalReason(null)
  }

  return (
    <div className='m-box quick-links'>
      <h2 className="-size-2xl">Quick links</h2>
      <TileGrid
        axis='xy'
        items={quickLinks}
        onSortEnd={onSortEnd}
        onAdd={onAdd}
        onRemove={onRemove}
        onReplace={onReplace}
        edit={edit}
        canAddTile={canAddTile()}
      />
      {modalReason && (
        <ReplaceModal
          onSelect={modalReason === 'add' ? add : replace}
          availableQuickLinks={availableQuickLinks}
          cancel={closeModal}
          reason={modalReason}
        />
      )}
      {!isEngineer && (
        <div className='a-link'>
          <button
            role="button"
            onClick={() => {
              if (edit) save()
              setEdit(!edit)
            }}
            className='quick-links__edit'
          >
            <i className={`a-icon boschicon-bosch-ic-${edit ? 'save' : 'edit'}`} title="pencil-edit"></i>
            <span>{edit ? 'Save your quick links' : 'Edit your quick links'}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default QuickLinkGrid
