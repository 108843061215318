import React, { useState } from 'react'

const ShopLandingButtons = props => {
  const { browseShopUrl, searchResultUrl } = props
  const [isSearch, setIsSearch] = useState()
  const [query, setQuery] = useState('')

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  const handleSearch = () => {
    window.location.href = `${searchResultUrl}?query=${query}`
  }

  if (isSearch) {
    return <div className="shop-search">
      <button className="shop-search__button" onClick={handleSearch}><span className='shop-search__button-icon'></span></button>
      <input type="search" name="query" className="form-control shop-search__input" placeholder="Search Products" onChange={e => setQuery(e.target.value)} onKeyDown={handleKeyDown}></input>
      <button aria-label="Close" className="shop-search__close-button" onClick={() => setIsSearch(false)}>x</button>
    </div>
  }

  return (
    <div className="shop-button-wrapper">
      <button className="button__primary--white text-center" onClick={() => setIsSearch(true)}>
        I know what I&apos;m looking for
      </button>
      <a className="button__primary--white text-center" href={browseShopUrl}>
        Browsing for items
      </a>
    </div>
  )
}

export default ShopLandingButtons
