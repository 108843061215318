import { useEffect, useState } from 'react'
import getGuaranteesRequest from '../network/requests/getGuaranteesRequest'

const usePagination = (initialQueryParams, incompleteOnly = false) => {
  const [guarantees, setGuarantees] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const PAGE_SIZE = incompleteOnly ? 4 : 10

  const fetchGuarantees = async () => {
    setIsLoading(true)
    setError(null)
    try {
      const queryParams = { ...initialQueryParams, page: { number: currentPage, size: PAGE_SIZE } }
      const result = await getGuaranteesRequest(queryParams)
      setGuarantees(result.data)
      setTotalPages(result.meta.pagination.total_pages)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchGuarantees()
      .then(response => {
        setError(null)
      })
      .catch(err => {
        setError(err.message)
      })
  }, [currentPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const refreshGuarantees = async () => {
    await fetchGuarantees()
  }

  return { guarantees, isLoading, error, setError, currentPage, totalPages, handlePageChange, refreshGuarantees }
}

export default usePagination
