// Backend version: app/WorcesterBosch/Guarantee/Shared/Repository/GuaranteeStatus.php

const STATUSES = [
  'amending',
  'draft',
  'pending',
  'requires_verification',
  'evidence_draft',
  'verification_rejected_with_reason',
]

export const isGuaranteeEditable = status => {
  return STATUSES.includes(status)
}
