import React, { Fragment } from 'react'

export const renderPagination = (currentPage, totalPages, handlePageChange) => {
  const range = 2
  const pageNumbers = [1] // Always include the first page

  // Logic to determine if ellipsis is needed on the left or right side
  let ellipsisLeft = currentPage > range + 2
  let ellipsisRight = currentPage < totalPages - range - 1

  // Start from the second page since the first page is always rendered
  for (let num = 2; num < totalPages; num++) {
    if (num >= currentPage - range && num <= currentPage + range) {
      pageNumbers.push(num)
    }
  }

  // Always include the last page if it's not already in the pageNumbers
  if (!pageNumbers.includes(totalPages)) {
    pageNumbers.push(totalPages)
  }

  return (
    <div className="a-page-indicator a-page-indicator--numbered --centered" role="navigation">
      <button
        type="button"
        className={`a-page-indicator__caret -left ${currentPage === 1 ? '-end' : ''}`}
        aria-label="Go to previous page"
        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      ></button>
      <div className="a-page-indicator__container">
        {pageNumbers.map((pageNumber, index) => (
          <Fragment key={pageNumber}>
            {ellipsisLeft && index === 1 && (
              <button type="button" className="a-page-indicator__indicator" aria-label="slide ...">
                <span>...</span>
              </button>
            )}
            <button
              data-index={pageNumber}
              className={`a-page-indicator__indicator ${currentPage === pageNumber ? '-selected' : ''}`}
              onClick={() => handlePageChange(pageNumber)}
              aria-label={`Go to page ${pageNumber}`}
              disabled={currentPage === pageNumber}
            >
              {pageNumber}
            </button>
            {ellipsisRight && index === pageNumbers.length - 2 && (
              <button type="button" className="a-page-indicator__indicator" aria-label="slide ...">
                <span>...</span>
              </button>
            )}
          </Fragment>
        ))}
      </div>
      <button
        type="button"
        className={`a-page-indicator__caret -right ${currentPage === totalPages ? '-end' : ''}`}
        aria-label="Go to next page"
        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      ></button>
    </div>
  )
}
