import mountReact from '../shared/mountReact'
import ExpressInstallerAvailability from './components/ExpressInstallerAvailability'
import FastTrackInstallerAvailability from './components/FastTrackInstallerAvailability'
import ExpressInstallerImageUpload from './components/ExpressInstallerImageUpload'
import ExpressInstallerComments from './components/ExpressInstallerComments'

import AccordionForm from '../customer-loyalty/components/AccordionForm/AccordionForm'
import FrokAccordionForm from '../customer-loyalty/components/FrokAccordionForm/FrokAccordionForm'
import DashboardQuickLinks from '../customer-loyalty/components/Dashboard/QuickLinkGrid'
import Onboarding from '../customer-loyalty/components/Onboarding'
import FrokExtendedGuarantees from '../customer-loyalty/components/FrokExtendedGuarantees'
import Lightbox from '../customer-loyalty/components/Lightbox'
import FastTrackInstallerImageUpload from './components/FastTrackInstallerImageUpload'
import InstallerOnboardingModal from '../hhic/components/InstallerOnboardingModal'
import ConnectAccount from '../hhic/components/ConnectAccount'
import TermsButtons from '../hhic/components/TermsButtons'
import LeadResponsesChart from './components/LeadResponsesChart'
import ProfileViewsChart from './components/ProfileViewsChart'
import TotalRegistrationsChart from './components/TotalRegistrationsChart'
import WaiSearchField from './components/WaiSearchField'
import ShopLandingButtons from './components/ShopLandingButtons'
import OutOfOffice from './components/OutOfOffice'
import FastTrackOrderOutcome from './components/FastTrackOrderOutcome'
import EngineerGuarantees from './components/EngineerGuarantees/Guarantees'

mountReact(ExpressInstallerAvailability, '#wai-availability-mount')
mountReact(FastTrackInstallerAvailability, '#fast-track-availability-mount')
mountReact(ExpressInstallerImageUpload, '#wai-imageUpload-mount')
mountReact(FastTrackInstallerImageUpload, '#fasttrack-imageUpload-mount')
mountReact(FastTrackOrderOutcome, '#fasttrack-outcome-mount')
mountReact(ExpressInstallerComments, '#wai-comments-mount')

mountReact(AccordionForm, '.js-loyalty-accordion-settings')
mountReact(FrokAccordionForm, '.a-frok-accordion-form')
mountReact(DashboardQuickLinks, '#wai-dashboard-quicklinks-mount')
mountReact(Onboarding, '#wai-onboarding-mount')
mountReact(Lightbox, '.lightbox-mount')
mountReact(FrokExtendedGuarantees, '#extended-guarantee-mount')
// mountReact(ExtendedGuarantees, '#extended-guarantee-mount')
mountReact(InstallerOnboardingModal, '#hhic-onboarding-mount')
mountReact(ConnectAccount, '#hhic-connect-account-mount')
mountReact(TermsButtons, '#hhic-terms-buttons-mount')
mountReact(WaiSearchField, '#search-field-mount')
mountReact(LeadResponsesChart, '#lead-responses-chart')
mountReact(ProfileViewsChart, '#profile-views-chart')
mountReact(TotalRegistrationsChart, '#total-registrations-chart')
mountReact(ShopLandingButtons, '#shop-landing-buttons')
mountReact(OutOfOffice, '#out-of-office')
mountReact(EngineerGuarantees, '#engineer-guarantees-mount')
