import React, { Component } from 'react'
import DayPicker from 'react-day-picker'
import difference from 'lodash/difference'
import dayjs from 'dayjs'
import getExpressAvailabilityRequest from '../network/requests/getExpressAvailabilityRequest'
import selectByWeekdayReducer from '../state/reducers/selectByWeekdayReducer'
import NavBar from './DatePicker/NavBar'
import updateFastTrackSurvey from '../network/requests/updateFastTrackSurvey'
import FastTrackSurvey from './waiFastTrackSurvey.js'
import BtnLoader from '../../shared/components/BtnLoader'

export default class ExpressInstallerAvailability extends Component {
  constructor (props) {
    super(props)
    this.state = {
      originalAvailableDays: [],
      availableDays: [],
      disabledDays: [],
      datesToAdd: [],
      datesToDelete: [],
      loading: false,
      activeMonth: dayjs().unix()
    }
  }

  componentDidMount () {
    this.setState({ loading: true })
    getExpressAvailabilityRequest().then(dates =>
      this.setState({
        originalAvailableDays: dates.filter(
          date => date.status === 'available'
        ),
        availableDays: dates.filter(date => date.status === 'available'),
        disabledDays: dates.filter(date => date.status === 'disabled'),
        loading: false
      })
    )
  }

  handleDayClick = (day, { disabled, selected }) => {
    if (disabled) return
    const today = dayjs(day).startOf('day')
    if (selected) {
      // Deselect the day if it's already been selected
      this.setState(state => ({
        availableDays: state.availableDays.filter(
          day => !dayjs.unix(day.id).isSame(today)
        )
      }))
    } else {
      this.setState(state => ({
        availableDays: [
          ...state.availableDays,
          { id: today.unix(), status: 'available' }
        ]
      }))
    }
  };

  handleSelectAll = weekday => {
    const today = dayjs().startOf('day')
    this.setState(state => selectByWeekdayReducer(state, weekday, today))
  };

  handleSubmit = surveyData => {
    this.setState({ loading: true })
    const oldAvailableDates = this.state.originalAvailableDays
    const newAvailableDates = this.state.availableDays
    const oldDatesRemoved = difference(
      oldAvailableDates,
      newAvailableDates
    ).map(toApi)
    const newDatesAdded = difference(newAvailableDates, oldAvailableDates).map(
      toApi
    )
    this.setState(
      {
        datesToDelete: oldDatesRemoved,
        datesToAdd: newDatesAdded
      },
      () => {
        if (!surveyData || surveyData.length === 0) return this.form.submit()
        updateFastTrackSurvey(surveyData)
          .then(() => this.form.submit())
          .catch(err => WB.alert(err.statusText, { status: 'danger' }))
      }
    )
  };

  handleMonthChange = (month, callbackFn) => {
    this.setState({ activeMonth: month.unix() })
    callbackFn()
  };

  renderWeekday = ({ weekday, className }) => {
    const label = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][weekday]
    return (
      <div className={className}>
        <span className="label">{label}</span>
        {weekday !== 0 && (
          <div>
            <a onClick={() => this.handleSelectAll(weekday)}>Select all</a>
          </div>
        )}
      </div>
    )
  };

  renderDay = (day, { selected }) => {
    const dateObject = dayjs(day)
    return (
      <div className="DayElement">
        <span className="day">{dateObject.format('D')}</span>
        {selected && (
          <img
            src={'/img/fast-track/circle-checkmark.svg'}
            style={{ height: '20px' }}
          />
        )}
      </div>
    )
  };

  renderNavBar = ({ month, onPreviousClick, onNextClick }) => {
    return (
      <NavBar
        month={month}
        onPreviousClick={previousMonth =>
          this.handleMonthChange(previousMonth, onPreviousClick)
        }
        onNextClick={nextMonth =>
          this.handleMonthChange(nextMonth, onNextClick)
        }
      />
    )
  };

  render () {
    const currentMonth = dayjs()
    const nextMonth = dayjs().add(1, 'month')
    const {
      disabledDays,
      availableDays,
      surveys,
      datesToAdd,
      datesToDelete
    } = this.state
    return (
      <div>
        <section className="band">
          <div className="band-body">
            <h2>Manage Installation Availability</h2>
            <p>
              When a customer requests installation on a specific day we assign
              an installer who is available on that day. To ensure we only send
              jobs which are suitable, please select which days you are
              available to complete FastTrack installations.
            </p>
            <DayPicker
              captionElement={() => null}
              fromMonth={currentMonth.toDate()}
              toMonth={nextMonth.toDate()}
              onDayClick={this.handleDayClick}
              selectedDays={availableDays.map(toDate)}
              navbarElement={this.renderNavBar}
              renderDay={this.renderDay}
              weekdayElement={this.renderWeekday}
              disabledDays={[
                ...disabledDays.map(toDate),
                { before: new Date() }
              ]}
              firstDayOfWeek={1}
            />
            <BtnLoader
              onClick={() => this.handleSubmit()}
              className='btn btn-primary right-to-left'
              style={{ marginTop: 30, marginBottom: 30 }}
            >
              Set installation availability
            </BtnLoader>
          </div>
        </section>
        <FastTrackSurvey onSubmit={this.handleSubmit} />
        <form
          action="/fast-track/availability"
          method="post"
          ref={element => {
            this.form = element
          }}
          style={{ display: 'none' }}
        >
          <input type="hidden" name="_token" value={this.props.token} />
          <input type="text" name="surveys" value={surveys ? 1 : 0} />
          {datesToAdd.map(date => (
            <input key={date} type="text" name="available[]" value={date} />
          ))}
          {datesToDelete.map(date => (
            <input key={date} type="text" name="delete[]" value={date} />
          ))}
        </form>
      </div>
    )
  }
}
const toDate = day => dayjs.unix(day.id).toDate()
const toApi = day => parseInt(day.id)
