import React from 'react'
import FrokText from './FrokText'

function FrokList ({ name, onChange, value, options, editable, error }) {
  const onChangeItem = (newValue, index) => {
    const updatedValue = value.map((item, i) => (i === index ? newValue : item))
    onChange(name, updatedValue)
  }

  const listItems = value.map((item, i) => {
    if (!editable) return <li key={i}>{item}</li>
    return (
      <li key={i}>
        <FrokText
          editable
          error={error}
          name={name + '[' + i + ']'}
          onChange={(_, value) => onChangeItem(value, i)}
          value={item === null ? '' : item}
        />
      </li>
    )
  })

  if (options && options.ordered) {
    return (
      <ol className="-no-bullets m-b-0">{listItems}</ol>
    )
  }

  return <ul className="-no-bullets m-b-0">{listItems}</ul>
}

export default FrokList
