import dayjs from 'dayjs'

export default (state, weekday, currentDay) => {
  const { activeMonth, availableDays, disabledDays } = state
  const startOfMonth = dayjs.unix(activeMonth).startOf('month').startOf('day')
  const daysInMonth = startOfMonth.daysInMonth()

  const daysToAdd = []

  // we convert to string because dates from the server might be a string, but local dates may be an integer
  const availableDayIds = availableDays.map(date => String(date.id))
  const disabledDayIds = disabledDays.map(d => String(d.id))

  // Loop over all days in the month
  for (let index = 1; index <= daysInMonth; index++) {
    const dayInMonth = startOfMonth.set('date', index)
    if (dayInMonth.day() !== weekday || dayInMonth < currentDay) continue
    const id = String(dayInMonth.unix())
    const newDay = { id, date: id, status: 'available' }

    // Check the date doesn't already exist in state (so we don't duplicate it)
    if (availableDayIds.includes(id) || disabledDayIds.includes(id)) continue

    daysToAdd.push(newDay)
  }

  return {
    ...state,
    availableDays: [
      ...availableDays,
      ...daysToAdd
    ]
  }
}
