import { object, string, number, boolean } from 'yup'

const typesByFieldType = {
  text: string,
  textArea: string,
  url: string,
  number,
  boolean,
}

const createSchema = fields => {
  const schema = {}

  fields.forEach(field => {
    const { type, label, validation: rules } = field
    if (!rules || !typesByFieldType[type]) return

    let validator = typesByFieldType[field.type]()

    Object.keys(rules).forEach(key => {
      if (rules[key].length === 0) return
      rules[key].forEach(rule => {
        // chain all the rules onto the base type
        if (validator[rule]) {
          validator = validator[rule]()
        }
      })

      schema[field.name] = validator.label(label)
    })
  })

  return object().shape(schema)
}

export default createSchema
