import React, { useState, useEffect } from 'react'
import getFastTrackSurveyRequest from '../network/requests/getFastTrackSurveyRequest'
import FrokBtnLoader from '../../shared/components/FrokBtnLoader.js'
import produce from 'immer'
import startCase from 'lodash/startCase'
import FrokCheckbox from './FrokCheckbox'

const FastTrackSurvey = ({ onSubmit }) => {
  const [surveyDays, setSurveyDays] = useState([])
  const [loading, setLoading] = useState(false)

  const getFastTrackSurveySlots = () => {
    setLoading(true)
    getFastTrackSurveyRequest()
      .then(surveyDays => {
        setSurveyDays(surveyDays)
        setLoading(false)
      })
      .catch(err => {
        WB.alert(
          `Could not load your FastTrack Survey availability slots. Error: ${err.statusText}`,
          { status: 'danger' },
        )
      })
  }

  useEffect(() => {
    getFastTrackSurveySlots()
  }, [])

  const handleChangeSurvey = (label, item, isChecked) => {
    setSurveyDays(produce(surveyDays, draft => {
      const date = draft.find(input => input.id === label.id)
      date[item] = isChecked
    }))
  }

  const handleSubmit = () => {
    onSubmit(surveyDays)
  }

  const renderTable = () => {
    const header = ['Day', 'AM', 'PM']
    return (
      <div>
        <p>Your survey availability is separate from your installation availability above. By default you will be set as available for surveys on any day of the week and time slot. Please select the days and times you want to be sent surveys on. </p>
        <div className="table__overflow">
          <table className="m-table fast-track-table">
            <thead>
              <tr>
                {header.map((th, ix) => (
                  <th key={ix}>{th}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {surveyDays.map((label, ix) => (
                <tr data-th="#" key={ix}>
                  <td width="30%">{startCase(label.day)}</td>
                  <td>
                    <FrokCheckbox checked={label.am} day={`availability_${label.day}_am`} onChange={checked => handleChangeSurvey(label, 'am', checked)} />
                  </td>
                  <td>
                    <FrokCheckbox checked={label.pm} day={`availability_${label.day}_pm`} onChange={checked => handleChangeSurvey(label, 'pm', checked)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <section className='band__fasttrack--current'>
        <div className='container__wai--jobs'>
          <p>Loading</p>
        </div>
      </section>
    )
  }

  return (
    <section className='band__fasttrack--current'>
      <div className='container__wai--jobs'>
        <h2>Manage Survey Availability</h2>
        <div className="margin-top">
          <h4>Survey Day Preference</h4>
        </div>
        {renderTable()}
        <FrokBtnLoader
          onClick={handleSubmit}
          loading={loading}
          className='a-button a-button--primary -without-icon'
        >
          Set survey availability
        </FrokBtnLoader>
      </div>
    </section>
  )
}

export default FastTrackSurvey
