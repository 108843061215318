import React from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'

export default function NavBar ({ month, onPreviousClick, onNextClick }) {
  const today = dayjs()
  month = dayjs(month).startOf('month')

  const nextMonth = month.add(1, 'month')
  const previousMonth = month.subtract(1, 'month')

  const leftBtnClass = classNames('button icon icon-arrow-left', { 'hide-btn': today.startOf('month').isSame(month) })
  const rightBtnClass = classNames('button icon icon-arrow-right', { 'hide-btn': dayjs(month).month() === today.add(1, 'month').month() })

  return (
    <div className="DayPicker-Nav">
      <span className={leftBtnClass} role="button" onClick={() => onPreviousClick(previousMonth)} />
      <p className="month">{month.format('MMMM YYYY')}</p>
      <span className={rightBtnClass} role="button" onClick={() => onNextClick(nextMonth)} />
    </div>
  )
}
