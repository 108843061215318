import React, { useState, useEffect } from 'react'
import getFastTrackSurveyRequest from '../network/requests/getFastTrackSurveyRequest'
import BtnLoader from '../../shared/components/BtnLoader.js'
import produce from 'immer'
import startCase from 'lodash/startCase'
import Checkbox from './Checkbox'

const FastTrackSurvey = ({ onSubmit }) => {
  const [surveyDays, setSurveyDays] = useState([])
  const [loading, setLoading] = useState(false)

  const getFastTrackSurveySlots = () => {
    setLoading(true)
    getFastTrackSurveyRequest()
      .then(surveyDays => {
        setSurveyDays(surveyDays)
        setLoading(false)
      })
      .catch(err => {
        WB.alert(
          `Could not load your FastTrack Survey availability slots. Error: ${err.statusText}`,
          { status: 'danger' },
        )
      })
  }

  useEffect(() => {
    getFastTrackSurveySlots()
  }, [])

  const handleChangeSurvey = (label, item, isChecked) => {
    setSurveyDays(produce(surveyDays, draft => {
      const date = draft.find(input => input.id === label.id)
      date[item] = isChecked
    }))
  }

  const handleSubmit = () => {
    onSubmit(surveyDays)
  }

  const renderTable = () => {
    const header = ['Day', 'AM', 'PM']
    return (
      <div>
        <p>Your survey availability is separate from your installation availability above. By default you will be set as available for surveys on any day of the week and time slot. Please select the days and times you want to be sent surveys on. </p>
        <table className="table">
          <thead>
            <tr>
              {header.map((th, ix) => (
                <th key={ix}>{th}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {surveyDays.map((label, ix) => (
              <tr data-th="#" key={ix}>
                <td>{startCase(label.day)}</td>
                <td>
                  <Checkbox checked={label.am} onChange={checked => handleChangeSurvey(label, 'am', checked)} />
                </td>
                <td>
                  <Checkbox checked={label.pm} onChange={checked => handleChangeSurvey(label, 'pm', checked)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  if (loading) {
    return (
      <section className='band band-grey'>
        <div className='band-body'>
          <div className='container'>
            <p>Loading</p>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className='band band-grey'>
      <div className='band-body'>
        <div className='container'>
          <h2>Manage Survey Availability</h2>
          <div className="margin-top">
            <h4>Survey Day Preference</h4>
          </div>
          {renderTable()}
          <BtnLoader
            onClick={handleSubmit}
            loading={loading}
            className='btn btn-primary right-to-left'
          >
            Set survey availability
          </BtnLoader>
        </div>
      </div>
    </section>
  )
}

export default FastTrackSurvey
