import Presenter from '../../../shared/network/presenters/Presenter'
import { ProfilePresenter } from './ProfilePresenter'
import { PreferencesPresenter } from './PreferencesPresenter'
import { FastTrackPresenter } from './FastTrackPresenter'
import { ConsentTypesPresenter } from './ConsentTypesPresenter'
import { AccountPresenter } from './AccountPresenter'
import { FastTrackPreferencesPresenter } from './FastTrackPreferencesPresenter'

export class CompanyPresenter extends Presenter {
  relationships () {
    return {
      profile: ProfilePresenter,
      preferences: PreferencesPresenter,
      fastTrack: FastTrackPresenter,
      consent: ConsentTypesPresenter,
      account: AccountPresenter,
      fastTrackPreferences: FastTrackPreferencesPresenter,
    }
  }
}

CompanyPresenter.prototype.type = 'company'

// fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(CompanyPresenter, Presenter)

export default data => CompanyPresenter.render(data)
