import uuid from 'uuid/v4'
import _ from 'lodash'

const accountFields = [
  'hasSmsNotification',
]

// convert the flat form data into a shape the presenter will recognise
export default function notificationPreferencesFormatter (data) {
  data = _(data).omitBy(_.isUndefined).omitBy(_.isNull)

  return {
    id: uuid(),
    consent: {
      id: uuid(),
      ...data.omit(accountFields).value()
    },
    account: {
      id: uuid(),
      ...data.pick(accountFields).value()
    }
  }
}
