import React, { useState } from 'react'

/**
 * Extended guarantees select component
 */
export default function FrokExtendedGuarantees ({ guarantees, guaranteeCharge }) {
  const [selectedGuarantee, setSelectedGuarantee] = useState(0)
  const handleChange = event => event.target.value ? setSelectedGuarantee(event.target.value) : setSelectedGuarantee(0)
  const defaultCopy = () => {
    return (guarantees && guarantees.length > 0) ? 'Please select a guarantee' : 'You have no guarantees to extend.'
  }
  const getPaymentUrl = () => {
    return '/jobs/guarantees/extend/' + selectedGuarantee
  }
  const showExtra = () => selectedGuarantee !== 0

  return (
    <div>
      <div className="input__container--select">
        <label htmlFor="guarantee" className="-size-l">Please select a guarantee to extend</label>
        <div className="m-form-field m-form-field--dropdown">
          <div className="a-dropdown">
            <select name="guarantee" id="guarantee" onChange={handleChange} data-name="guarantee">
              <option value="">{defaultCopy()}</option>
              {guarantees && guarantees.map(guarantee => (
                <option value={guarantee.key} key={guarantee.key}>
                  {guarantee.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {showExtra() && (
        <div>
          <br/>
          <p className="text__paragraph--headings">
            You are about to extend the above guarantee. By extending this guarantee
            you agree to our guarantee terms and conditions, and to make a payment
            of {guaranteeCharge} for the extension. Upon receipt of your payment we will process
            your guarantee extension. Please be aware this may take up to 24 hours
            to update across our systems.
          </p>
          <p className="text__paragraph--headings">
            <strong>
              Please remember that it is your responsibility to notify the customer
              of their guarantee extension and provide them with an updated guarantee certificate.
            </strong>
          </p>
          <p className="text__paragraph--headings">When you are ready to continue please select below to proceed to our payment area.</p>
          <div className='fieldset__ctas--right'>
            <div className="a-link a-link--button">
              <a href={getPaymentUrl()}>
                <span>Proceed to payment</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
