import React, { useRef, useState } from 'react'
import useOutsideClick from '../../shared/hooks/useOutsideClick'
import useAutocompleteSearch from '../../shared/hooks/useAutocompleteSearch'

const WaiSearchField = (props = {}) => {
  const { siteArea, site } = props
  const inputRef = useRef()
  const containerRef = useRef()
  const [visible, setVisible] = useState(false)
  useOutsideClick(containerRef, () => setOpen(false))
  useOutsideClick(containerRef, () => setVisible(false))

  const makeUrl = () => `/search-rac?site=${site}&site-area=${siteArea}&q=${inputRef.current.value}`
  const [suggestions, onInputChange, { open, setOpen }] = useAutocompleteSearch({ makeUrl })

  function toggleSearch (event) {
    setVisible(!visible)
  }

  function handleEnterKey (event) {
    if (event.key === 'Enter') {
      window.location = `/search?q=${encodeURIComponent(inputRef.current.value)}`
    }
  }

  const renderMatch = label => {
    if (!label) return ''
    const regex = new RegExp(inputRef.current.value, 'gi')
    const replaced = label.replace(regex, match => {
      return `<strong className='search__match'>${match}</strong>`
    })
    return replaced
  }

  return (
    <div className={`o-search__container ${visible ? 'm-search-visible' : ''}`} ref={containerRef}>
      <div className="search__input-wrapper">
        <div className="m-form-field">
          <div className="a-text-field">
            <input
              ref={inputRef}
              onChange={onInputChange}
              onKeyDown={handleEnterKey}
              placeholder="Search"
              name="wai_search"
              id="wai_search"
              aria-label="Search"
              onFocus={() => setOpen(true)}
            />
          </div>
        </div>
        <button className="a-search__toggle" onClick={toggleSearch}>
          <i className="a-icon boschicon-bosch-ic-search" title="Open search"></i>
          <i className="a-icon a-button__icon boschicon-bosch-ic-close" title="Close search"></i>
          <span className="a-button__label">Search</span>
        </button>
      </div>

      {open && suggestions.length !== 0 && (
        <div tabIndex={0} className="m-search__results">
          {suggestions.map((suggestion, ix) => (
            <a className="a-search__link" key={ix} href={suggestion.pageURL}>
              <span className="a-search__item" dangerouslySetInnerHTML={{ __html: renderMatch(suggestion.name) }} />
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default WaiSearchField
