import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Checkbox extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool
  }

  static defaultProps = {
    checked: false
  }

  render () {
    const { checked, onChange } = this.props
    return (
      <div onClick={() => onChange(!checked)} className={classNames('availability-checkbox', { checked })}>
        <i className='icon icon-check' />
      </div>
    )
  }
}
