import React, { Fragment, useState } from 'react'
import disconnectAccount from '../network/requests/disconnectAccount'
import DisconnectAccountModal from './DisconnectAccountModal'
import InstallerOnboardingModal, { CONTENT_STATE } from './InstallerOnboardingModal'

const ConnectAccount = props => {
  const { isConnected, tcLink, showDisconnect } = props
  const [connected, setConnected] = useState(isConnected)
  const [onboardingModal, setOnboardingModal] = useState(false)
  const [disconnectModal, setDisconnectModal] = useState(false)

  const handleDisconnect = async () => {
    await disconnectAccount()
    setConnected(false)
    setDisconnectModal(false)
  }

  return (
    <Fragment>
      {connected ? (
        <Fragment>
          <div className='details__value'>Accounts connected</div>
          {Boolean(showDisconnect) && (
            <div className="a-link a-link--button">
              <a className='details__link' onClick={() => setDisconnectModal(true)}>
                <span>Disconnect accounts</span>
              </a>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="a-notification -neutral" role="alert">
            <i className="a-icon ui-ic-alert-info"></i>
            <div className="a-notification__content">
              Not connected
            </div>
          </div>
          <div className="a-link a-link--button">
            <a className='details__link' onClick={() => setOnboardingModal(!onboardingModal)}>
              <span>Connect accounts</span>
            </a>
          </div>
        </Fragment>
      )}
      {onboardingModal && (
        <div className='onboarding__modal--container!loyalty'>
          <InstallerOnboardingModal
            onClose={hasConnected => {
              setOnboardingModal(false)
              setConnected(hasConnected)
            }}
            tcLink={tcLink}
            initialContentState={CONTENT_STATE.LETS_CONNECT}
          />
        </div>
      )}
      {disconnectModal && <DisconnectAccountModal onCancel={() => setDisconnectModal(false)} onConfirm={handleDisconnect} />}
    </Fragment>
  )
}

export default ConnectAccount
