import React, { Fragment, useState } from 'react'
import uploadFileRequest from '../../../network/requests/uploadFileRequest'
import ImageUpload from '../../../../fast-track/screens/PhotoUploadScreen/components/FrokImageUpload'

function FrokImage ({ name, onChange, value, editable, error }) {
  // TODO loading overlay for file uploads
  // also need to pass up to parent form to disable the submit button
  // const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(value)
  const [filename, setFilename] = useState()
  const [errorLabel, setErrorLabel] = useState()

  const handleChange = (file) => {
    // setLoading(true)
    setUrl(URL.createObjectURL(file))
    setFilename(file.name)
    setErrorLabel()
    uploadFileRequest(file).then((response) => {
      // setLoading(false)
      onChange(name, Number(response.id))
    })
  }

  const handleError = (error) => {
    setErrorLabel(error)
  }

  return (
    <Fragment>
      {url && <img className="previewImage" style={{maxWidth: '100%'}} src={url} />}
      {editable && (
        <ImageUpload
          error={error}
          onChange={image => handleChange(image)}
          onError={error => handleError(error)}
          errorLabel={errorLabel}
          filename={filename}
        />
      )}
    </Fragment>
  )
}

export default FrokImage
