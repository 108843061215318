import React from 'react'

export default function TermsAndConditionsSlide ({ setName, errors, terms, setAcceptedTerms, acceptedTerms }) {

  const onChange = (termId) => {
    setAcceptedTerms({
      ...acceptedTerms,
      [termId]: !acceptedTerms[termId]
    })
  }

  return (
    <div className='onboarding__slide'>
      <div className="onboarding__content">
        <p role="heading" className='onboarding__title'>New Terms &amp; Conditions</p>
        <div className="onboarding__text">
          <p>Your Terms and Conditions for our loyalty scheme, please follow the process below to read and accept them.</p>
          <p>This information is only used by us here at Worcester to send you relevant communication about products or promotions.</p>
          <p>Don&apos;t worry, it won&apos;t appear every time you log into your loyalty account, once you have updated this information it will not appear for another 12 months.</p>
          <br/>
          <div style={{textAlign: 'center'}}>
            {terms && terms.map(term => {
              return (
                <a
                  href={term.link}
                  key={term.id}
                  style={{color: '#fff'}}
                  target='_blank'
                  rel="noopener noreferrer"
                  className={'button__secondary modal__button'}
                >
                  View the {term.name}
                </a>
              )
            })}
          </div>
          <br/>
          <p>Please enter your name to confirm you agree</p>
          <div className={'input__container--text ' + (errors['name'] && 'has-error')}>
            <div className="m-form-field">
              <div className="a-text-field">
                <label htmlFor="firstName">Enter your name</label>
                <input
                  name="name"
                  type="text"
                  autoComplete="off"
                  placeholder={'Enter your name'}
                  onChange={(event => setName(event.target.value))} />
              </div>
              {errors['name'] && (
                <div className="a-notification a-notification--text -error" role="alert">
                  <i className="a-icon ui-ic-alert-error" title="Loren Ipsum"></i>
                  <div className="a-notification__content">
                    Please enter your name
                  </div>
                </div>
              )}
            </div>
          </div>
          {terms && terms.map(term => {
            return (
              <div className={'input__container--term ' + (errors[term.id] && 'has-error')} key={term.id}>
                <div className="m-form-field m-form-field--checkbox">
                  <div className="a-checkbox">
                    <input
                      value={term.id}
                      type="checkbox"
                      className='input__control--inverted'
                      id={`term-${term.id}`}
                      onChange={() => onChange(term.id)}/>
                    <label htmlFor={`term-${term.id}`}>I agree to the {term.name}</label>
                    {errors[term.id] && (
                      <div className="a-notification a-notification--text -error" role="alert">
                        <i className="a-icon ui-ic-alert-error" title="Error"></i>
                        <div className="a-notification__content">
                          Please accept the {term.name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
