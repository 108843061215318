import React, { Component } from 'react'
import Textarea from '../../shared/components/Textarea'
import BtnLoader from '../../shared/components/BtnLoader'
import updateOrderRequest from '../../fast-track/network/requests/updateOrderRequest'
import getErrorMessage from '../../fast-track/util/getErrorMessage'

class ExpressInstallerComments extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      submitting: false,
      comments: props.comments,
      error: null
    }
  }

  onCommentsChange = (value) => {
    this.setState({comments: value})
  }

  handleSubmitMessage = () => {
    this.setState({ loading: true })
    updateOrderRequest({ id: this.props.orderId, installerComments: this.state.comments })
      .then(() => {
        this.setState({
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error)
        })
      })
  }

  render () {
    const { loading, comments, error } = this.state
    return (
      <div className='col-md-12 band-grey band-body'>
        <h3 className='band-title margin-bottom margin-top'> Your Comments </h3>
        <Textarea
          name='message'
          onChange={this.onCommentsChange}
          value={comments}
        />
        <div className='pull-left'>
          These notes are private and can only be viewed by you.
        </div>
        <div className="pull-right margin-top">
          <BtnLoader
            onClick={this.handleSubmitMessage}
            altColor={true}
            loading={loading}
            className='btn btn-primary btn-block-xs margin-bottom'
          >
            Save
          </BtnLoader>
        </div>
        <div className='pull-left margin-bottom margin-top'>
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </div>
      </div>
    )
  }
}
export default ExpressInstallerComments
