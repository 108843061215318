import React, { useState } from 'react'

const OutOfOffice = () => {
  const [inOffice, setInOffice] = useState(false)

  function toggleInOffice (event) {
    setInOffice(!inOffice)
  }

  return (
    <div className="m-out-of-office__wrapper">
      <div className="a-toggle">
        <input type="checkbox" id="toggle-1" name="toggle-1" aria-describedby="toggle-1" onChange={toggleInOffice} />
        <label className="a-toggle__box"></label>
        <label className="a-toggle__label -right" htmlFor="toggle-1">
            You are <strong>{inOffice ? 'in the office' : 'out of the office'}</strong>
        </label>
      </div>
    </div>
  )
}

export default OutOfOffice
