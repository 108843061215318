import Presenter from '../../../shared/network/presenters/Presenter.js'

export class FastTrackSurveyPresenter extends Presenter {
  relationships () {
    return {}
  }
}

FastTrackSurveyPresenter.prototype.type = 'survey-availability'
// Fix for IE <= 10 (https://github.com/babel/babel/issues/116)
Object.assign(FastTrackSurveyPresenter, Presenter)
export default data => FastTrackSurveyPresenter.render(data)
