const ALERT_ID = 'react-benchmark-alert'

export const showAlert = (text, options = {}) => {
  WB.alert(text, {
    ...{
      id: ALERT_ID,
      status: 'danger'
    },
    ...options
  })
}

export const closeAlert = () => {
  WB.alertClose(ALERT_ID)
}
