import { useState } from 'react'
import useDebouncedCallback from '../../admin/cms-page-builder/hooks/useDebouncedCallback'
import { ajax } from '../../shared/network/api'

const useAutocompleteSearch = ({
  makeUrl,
}) => {
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const [fetchSuggestions] = useDebouncedCallback(() => {
    ajax(makeUrl(), {
      method: 'GET',
    }).then(result => {
      setSuggestions(result)
      setOpen(true)
    })
  }, 1000)



  const onInputChange = e => {
    const value = e.target.value
    if (!value) {
      setSuggestions([])
    } else {
      fetchSuggestions()
    }
  }

  return [
    suggestions,
    onInputChange,
    {
      open,
      setOpen
    }
  ]
}

export default useAutocompleteSearch
