import React from 'react'
import moment from 'moment'

const INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const PICKER_FORMAT = 'YYYY-MM-DD'
const RENDER_FORMAT = 'DD/MM/YYYY'

function FrokDateInput ({ name, onChange, value, editable, error, className, size, required, label, displayLabel, displayLabelAsTitle }) {
  const dateValue = value
    ? value.date ? value.date : value
    : null
  const parsed = dateValue ? moment(dateValue, INPUT_FORMAT) : null
  if (!editable) return parsed && parsed.isValid() ? parsed.format(RENDER_FORMAT) : ''

  const handleChange = e => {
    const date = moment(e.target.value, PICKER_FORMAT)
    onChange(name, { ...value, date: date.isValid() ? date.format(INPUT_FORMAT) : '' })
  }

  return (
    <div className={`m-form-field m-form-field--${size} ${className}`}>
      {displayLabel && displayLabelAsTitle && (
        <h3 className="-size-xl -strong">
          {label}{required && '*'}
        </h3>
      )}
      <div className="a-text-field">
        {displayLabel && !displayLabelAsTitle && (<label htmlFor={name}>{label}</label>)}
        <input
          className={'input__control'}
          onChange={handleChange}
          value={value === null ? '' : parsed.format(PICKER_FORMAT)}
          type="date"
          id={name}
        />
      </div>
      {error ? (
      <div className="a-notification a-notification--text -error" role="alert">
        <div className="a-notification__content">{error}</div>
      </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default FrokDateInput
