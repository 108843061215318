import React, { useState } from 'react'
import FrokDateInput from '../../../shared/components/FrokFormRow/FrokFormRowFields/FrokDateInput'
import FrokText from '../../../shared/components/FrokFormRow/FrokFormRowFields/FrokText'
import FrokSelect from './FrokSelect'

const INITIAL_STATE = {
  startDate: null,
  endDate: null,
  firstName: '',
  lastName: '',
  postcode: '',
  productCategory: ''
}
const Filter = ({ productCategories, onFilterChange }) => {
  const [filterData, setFilterData] = useState(INITIAL_STATE)

  const handleChange = (inputName, inputValue) => {
    let name, value

    if (inputName && inputName.target) {
      ({ name, value } = inputName.target)
    } else {
      name = inputName
      value = inputValue && inputValue.date ? inputValue.date : inputValue
    }

    setFilterData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleApplyFilters = () => {
    onFilterChange({ ...filterData })
  }

  const handleClearFilters = () => {
    setFilterData(INITIAL_STATE)
    onFilterChange(INITIAL_STATE)
  }

  return (
    <div className="m-filters">
      <button type="button" className="a-filter-toggle a-button a-button--tertiary" aria-controls="filters_0" aria-expanded="false">
        <i className="a-icon a-button__icon ui-ic-search" title="Filter"></i>
        <span className="a-button__label">Filter</span>
      </button>
      <div className="a-filter-fields" id="filters_0">
        <div className="accordion__body">
          <div className="excelerate-filters">
            <div className="o-form__row m-date--range">
              <FrokDateInput
                name="startDate"
                value={filterData.startDate}
                onChange={(name, value) => handleChange(name, value)}
                editable={true}
                className="-half"
                required={false}
                label="Date From"
                displayLabel={true}
                displayLabelAsTitle={false}
              />
              <div className="filter__text--to -quarter">to</div>
              <FrokDateInput
                name="endDate"
                value={filterData.endDate}
                onChange={(name, value) => handleChange(name, value)}
                editable={true}
                className="-half"
                required={false}
                label="Date To"
                displayLabel={true}
                displayLabelAsTitle={false}
              />
            </div>
            <FrokText
              name="firstName"
              value={filterData.firstName}
              onChange={handleChange}
              editable={true}
              required={false}
              label="First Name"
              displayLabel={true}
              displayLabelAsTitle={false}
            />
            <FrokText
              name="lastName"
              value={filterData.lastName}
              onChange={handleChange}
              editable={true}
              required={false}
              label="Last Name"
              displayLabel={true}
              displayLabelAsTitle={false}
            />
            <FrokText
              name="postcode"
              value={filterData.postcode}
              onChange={handleChange}
              editable={true}
              required={false}
              label="Postcode"
              displayLabel={true}
              displayLabelAsTitle={false}
            />
            <div className="o-form__row">
              <div className="m-form-field m-form-field--dropdown">
                <FrokSelect
                  label="Product category"
                  name="productCategory"
                  id="productCategory"
                  value={filterData.productCategory}
                  onChange={handleChange}
                  options={productCategories}
                />
              </div>
            </div>
          </div>
          <div className="inline__row--filters">
            <button
              className="a-button a-button--primary -without-icon"
              name="report_action"
              value="apply"
              onClick={handleApplyFilters}
            >
              <span className="a-button__label">Apply filters</span>
            </button>
            <button
              className="a-button a-button--secondary -without-icon"
              name="report_action"
              value="clear"
              onClick={handleClearFilters}
            >
              <span className="a-button__label">Clear filters</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filter
