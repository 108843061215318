import castArray from 'lodash/castArray'
import get from 'lodash/get'

export const findValue = (item, key) => {
  // Array
  if (Array.isArray(item)) {
    for (let i = 0; i < item.length; ++i) {
      const val = findValue(item[i], key)
      if (val !== undefined) return val
    }
    return undefined
  }
  // Object
  if (typeof item === 'object') {
    if (item[key] !== undefined) return item[key]
    return findValue(Object.values(item), key)
  }
  // Unknown
  return undefined
}

export const getPasswordError = err => {
  const error = findValue(err.responseJSON, 'currentPassword')
  if (error) return castArray(error).join('\n')
  return undefined
}

const UNEXPECTED_ERROR = 'An unexpected error has occured, please try again later'
export const getUserFriendlyErrorMessage = err => {
  const is5XX = err.status >= 500 && err.status <= 599
  if (is5XX) return UNEXPECTED_ERROR
  return get(err, 'responseJSON.errors.0.title', UNEXPECTED_ERROR)
}

export const getValidationErrorMap = (err) => {
  const errors = get(err, 'responseJSON.errors', []).filter(errorItem => {
    const field = get(errorItem, 'source.field')
    const detail = get(errorItem, 'detail')
    return field && detail
  })
  if (errors.length === 0) return undefined
  return errors.reduce((prev, curr) => {
    const field = get(curr, 'source.field')
    const detail = get(curr, 'detail')
    if (!field || !detail) return prev
    return {
      ...prev,
      [field]: detail
    }
  }, {})
}
