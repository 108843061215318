import React from 'react'

function FrokRadioButton ({ name, onChange, value, editable, error, options }) {

  if (!editable) {
    return options[value] || ''
  }

  return (
    <div>
    {
      Object.keys(options).map((option, ix) =>
      <div key={ix} className="input__container--radio">
        <input type="radio" name={name} className="input__control" onChange={() => onChange(name, option)} checked={option === value} />
        <label className="input__label">{options[option]}</label>
      </div>)
      }
    </div>
  )
}

export default FrokRadioButton
