import React, { useState, useEffect } from 'react'
import api from '../../shared/network/api'

export default function Lightbox ({ images, shouldFetch = false }) {
  const [activeImage, setActiveImage] = useState(null)
  const [contents, setContents] = useState([])
  useEffect(() => {
    const fetchContents = async () => {
      const contents = await Promise.all(images.map(async (image) => {
        const response = await api.fetch(image.src)
        const img = await response.blob()
        return URL.createObjectURL(img)
      }))
      setContents(contents)
    }

    if (shouldFetch) {
      fetchContents()
    }
  }, [])
  useEffect(() => {
    document.body.style.overflow = activeImage ? 'hidden' : 'auto'
  }, [activeImage])
  return (
    <div className="tile__fasttrack--proof">
      {images.map((image, index) => (
        <div className='tile__item' key={index}>
          <button
            className='image__preview--portrait'
            onClick={() => setActiveImage(shouldFetch ? contents[index] : image.src)}
            style={{ backgroundImage: `url(${shouldFetch ? contents[index] : image.src})` }}
            title={image.alt}
          >
          </button>
        </div>
      ))}
      <div onClick={() => setActiveImage(null)} className={activeImage ? 'lightbox__overlay--open' : 'lightbox__overlay'}>
        <div className="lightbox__modal">
          <button className="lightbox__close" onClick={() => setActiveImage(null)} aria-label="Close image">
            &times;
          </button>
          <img className="lightbox__image" src={activeImage} alt="" />
        </div>
      </div>
    </div>
  )
}
