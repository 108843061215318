import React from 'react'
import { unixTimestampFormatDate } from '../../utils/unixTimestampFormatDate'
import { capitalize } from 'lodash'
import GuaranteeActionsMenu from './GuaranteeActionsMenu'
import deleteIncompleteGuaranteesRequest from '../../network/requests/deleteIncompleteGuaranteeRequest'
import { LoadingSpinner, ErrorMessage, EmptyResult } from './Common'
import usePagination from '../../hooks/usePagination'
import { renderPagination } from '../../utils/renderPagination'

const Incomplete = ({ incompleteOnly }) => {
  const queryParams = {
    filter: {
      order: 'desc',
      onlyIncomplete: true,
      submittedByEngineer: false
    },
    include: [
      'customer',
      'customer.address',
    ].join(','),
  }

  const { guarantees, isLoading, error, setError, currentPage, totalPages, handlePageChange, refreshGuarantees } = usePagination(queryParams, incompleteOnly)

  const deleteGuarantee = async (guaranteeId) => {
    try {
      await deleteIncompleteGuaranteesRequest(guaranteeId)
      await refreshGuarantees()
    } catch (err) {
      setError(err.message)
    }
  }

  const renderBody = () => {
    if (isLoading) {
      return <LoadingSpinner />
    }

    if (error) {
      return <ErrorMessage message={error} />
    }

    if (guarantees.length === 0) {
      return <EmptyResult />
    }

    return guarantees.map((guarantee, index) => (
      <tr key={index}>
        <td>{unixTimestampFormatDate(guarantee.installationDate)}</td>
        <td>{guarantee.customer?.address?.postcode}</td>
        <td> {capitalize(guarantee.status_label)} </td>
        <td>
          <GuaranteeActionsMenu
            guarantee={guarantee}
            index={index}
            onDelete={() => deleteGuarantee(guarantee.id)}
          />
        </td>
      </tr>
    ))
  }

  const shouldRenderPagination = !incompleteOnly && guarantees.length > 0 && totalPages > 1

  return (
    <div className="incomplete-guarantees-container">
      {!incompleteOnly && (
        <h2 className="-size-2xl">Incomplete registrations</h2>
      )}
      <div className="table__overflow">
        <div id="incomplete-table">
          <table className="m-table" aria-label="Highlights">
            <thead>
              <tr>
                <th>Installed</th>
                <th>Postcode</th>
                <th>Status</th>
                <th width="160">Actions</th>
              </tr>
            </thead>
            <tbody>
              {renderBody()}
            </tbody>
          </table>
        </div>
      </div>
      {shouldRenderPagination && renderPagination(currentPage, totalPages, handlePageChange)}
    </div>
  )
}

export default Incomplete
