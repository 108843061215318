import { pick } from 'lodash'
import uuid from 'uuid/v4'

const companyFields = [
  'integrateTrustPilot',
  'trustPilotBusinessId',
  'whichTrustedTraderUrl'
]

const profileFields = [
  'description',
  'website',
  'threeSelling',
  'finance',
  'telephoneNumber',
  'emailAddress',
]

const preferencesFields = [
  'serviceWork',
  'repairWork',
]

// convert the flat form data into a shape the presenter will recognise
export default function installerProfileFormatter (data) {
  const profile = pick(data, profileFields)
  const address = typeof data.address === 'object' ? { id: uuid(), ...data.address } : { id: data.address }
  const logo = typeof data.logo === 'number' ? { id: data.logo } : null
  return {
    id: uuid(),
    ...pick(data, companyFields),
    profile: {
      id: uuid(),
      ...profile,
      address,
      logo,
    },
    preferences: {
      id: uuid(),
      ...pick(data, preferencesFields)
    }
  }
}
