import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Checkbox extends React.Component {

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    day: PropTypes.string
  }

  static defaultProps = {
    checked: false
  }

  render () {
    const { checked, onChange, day } = this.props
    return (
      <div className="a-checkbox">
        <input
          type="checkbox"
          id={day}
          name="availability_checkbox"
          className={classNames('availability-checkbox', { checked })}
          onChange={() => onChange(!checked)}
          checked={checked}
        />
        <label htmlFor={day}>Available</label>
      </div>
    )
  }
}
