import React from 'react'
import getChartColors from '../utils/getChartColors'
import LineChart from './LineChart'

const COLORS = ['#b12ea9', '#006ead', '#18837e']

const LeadResponsesChart = (props = {}) => {
  const { chart } = props
  const labelsLength = chart.labels.length

  const formatLineDatasets = datasets =>
    datasets.map((dataset, ix) => ({
      ...dataset,
      data: dataset.data.map((data, ix) => ({
        ...data,
        id: chart.labels[ix],
      })),
      backgroundColor: getChartColors(labelsLength, COLORS[ix]),
      borderColor: getChartColors(labelsLength, COLORS[ix]),
      borderWidth: 2,
    }))

  const config = {
    type: 'line',
    data: {
      labels: chart.labels,
      datasets: formatLineDatasets(chart.datasets),
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: 'id',
        yAxisKey: 'total'
      },
      scales: {
        x: {
          offset: true,
          display: true,
          title: {
            text: chart.xAxisText,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
          ticks: {
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: chart.yAxisText,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          ticks: {
            display: false,
            color: '#2e3033',
            font: {
              family: "'boschsans'",
              weight: 'bold'
            },
          },
          grid: {
            borderWidth: 2,
            borderColor: '#919599',
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            padding: 20,
            boxWidth: 10,
            usePointStyle: true,
            color: '#2e3033',
            font: {
              size: 18,
              family: "'boschsans'",
              weight: 'bold',
              lineHeight: 0
            },
          },
        },
        tooltip: {
          backgroundColor: '#ffffff',
          borderColor: '#f5f5f5',
          borderWidth: 1,
          titleColor: '#000000',
          padding: 10,
          displayColors: false,
          caretSize: 0,
          cornerRadius: 0,
          bodyColor: '#ffffff',
          bodyFont: {
            size: 18,
          },
          callbacks: {
            title: function () { return '' },
            labelTextColor: function (context) {
              return context.dataset.backgroundColor[0]
            },
            label: function (context) {
              const { urgent, total } = context.raw
              return `Total ${total} | Urgent ${urgent}`
            }
          }
        }
      }
    }
  }

  return (
    <>
      <h3 className="m-t-0">Online lead responses</h3>
      <div className="m-box">
        <div className="e-flex -column">
          <LineChart config={config} />
        </div>
      </div>
    </>
  )
}

export default LeadResponsesChart
