import React, { useState } from 'react'

function FrokPasswordModal ({ onSubmit, hide, error, loading }) {
  const [password, setPassword] = useState('')
  return (
    <div className='m-dialog -floating-shadow-s'>
      <div className="m-dialog__header">
        <div className="m-dialog__title">Enter Password</div>
      </div>

      <hr className="a-divider" />

      <div className="m-dialog__content">
        <p>To confirm the changes you have made to your profile, please enter your password.</p>
        <div className="m-form-field">
          <div className="a-text-field">
            <label htmlFor="secret">Your password</label>
            <input
              name='secret'
              type='password'
              autoComplete='off'
              value={password}
              onChange={e => setPassword(e.target.value)}
              className='input__control'
              disabled={loading}
            />
          </div>
          {error ? (
            <div className="a-notification a-notification--text -error" role="alert">
              <div className="a-notification__content">{error}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="m-dialog__actions">
        <button className='a-button a-button--secondary -without-icon' disabled={loading} onClick={() => { hide() }}>
          <span className="a-button__label">Cancel</span>
        </button> &nbsp;
        <button className='a-button a-button--primary -without-icon' disabled={loading} onClick={() => { onSubmit(password) }}>
          <span className="a-button__label">Submit</span>
        </button> &nbsp;
      </div>
    </div>
  )
}

export default FrokPasswordModal
