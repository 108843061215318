import React, { Component } from 'react'
import uploadFileRequest from '../../shared/network/requests/uploadFileRequest'
import updateOrderRequest from '../../fast-track/network/requests/updateOrderRequest'
import ImageUpload from '../../fast-track/screens/PhotoUploadScreen/components/ImageUpload'
import Screen from '../../fast-track/components/Screen'
import classNames from 'classnames'
import BtnLoader from '../../shared/components/BtnLoader'

class ExpressInstallerImageUpload extends Component {
  state = {
    errors: {},
    loading: false,
    images: {
      inside: null,
      outside: null,
      pipework: null,
      meter: null
    }
  }

  validate () {
    const { images } = this.state
    const errors = {}
    const keys = Object.keys(images)
    const img = Object.values(images)
    for (let key of keys) {
      const image = images[key]
      if (!image) {
        errors[key] = 'Please select an image'
      } else {
        const same = img.filter(i => isSameFile(i, image))
        if (same.length > 1) { errors[key] = 'Image must be unique' }
      }
    }

    this.setState({ errors })

    return Object.keys(errors).length > 0
  }

  handleClick = () => {
    const errors = this.validate()
    if (errors) return
    const { images } = this.state
    const expressOrder = {
      id: this.props.orderId
    }
    this.setState({ loading: true })
    const keys = Object.keys(images)
    return Promise.all(keys.map(key => uploadFileRequest(images[key])))
      .then(responses => {
        // loop over the return uploaded images and add the location to the object
        responses.forEach((response, index) => (response.location = keys[index]))
        // update the express order
        updateOrderRequest({
          ...expressOrder,
          'installer-evidence': responses
        })
          .then(() => {
            window.location.reload()
          })
      })
      .catch(error => {
        const errorMessage = error.responseJSON.errors.file[0]
        this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            general: `Error: ${errorMessage}`
          }
        })
      })
  }

  handleChange = (image, key) => {
    const file = new FileReader()
    file.onload = () => {
      const images = { ...this.state.images }
      images[key] = image
      this.setState({ images })
    }
    file.readAsDataURL(image)
  }

  handleError = (error, key) => {
    this.setState(state => ({
      errors: {
        ...state.errors,
        [key]: error
      }
    }))
  }

  renderFileInput (key, label) {
    const { images, errors } = this.state
    return (
      <div
        key={key}
        className="col-md-6"
      >
        <ImageUpload
          error={errors[key]}
          image={images[key] ? URL.createObjectURL(images[key]) : null}
          label={label}
          onChange={image => this.handleChange(image, key)}
          onError={error => this.handleError(error, key)}
        />
      </div>
    )
  }

  render () {
    const { loading, errors } = this.state
    const { description } = this.props
    const error = errors.general
    return (
      <Screen>
        <div>
          <h3>Upload proof photos</h3>
          <div className="text-center">
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
          <div className="row">
            {this.renderFileInput('inside', 'Photo of boiler inside house')}
            {this.renderFileInput('outside', 'Photo of flue outside house')}
            {this.renderFileInput('pipework', 'Photo of boiler pipework inside house')}
            {this.renderFileInput('meter', 'Photo of the gas meter')}
          </div>
          <div className={classNames({ 'has-error': error })}>
            <span className={'pull-left help-block'}>{error}</span>
          </div>
        </div>
        <div className="text-center margin-top">
          <BtnLoader
            altColor={true}
            loading={loading}
            onClick={this.handleClick}
            className='btn btn-primary'
          >
            Upload
          </BtnLoader>
        </div>
      </Screen>
    )
  }
}

function isSameFile (file1, file2) {
  if (!file1 || !file2) return false
  return (
    file1.name === file2.name &&
    file1.size === file2.size &&
    file1.lastModified === file2.lastModified
  )
}
export default ExpressInstallerImageUpload
