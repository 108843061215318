import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import updateOrderRequest from '../../fast-track/network/requests/updateOrderRequest'
import { get } from 'lodash'
import FrokBtnLoader from '../../shared/components/FrokBtnLoader'
import FrokNotification from '../../shared/components/FrokNotification'
import FrokSelect from '../../shared/components/FrokSelect'

const FastTrackOrderOutcome = ({ orderId, orderOutcome, outcomes, cancellationReason }) => {
  const [outcome, setOutcome] = useState(orderOutcome?.outcome ?? '')
  const [showCancellationReason, setShowCancellationReason] = useState(false)
  const [errors, setErrors] = useState({})

  const cancellationReasonInput = useRef()

  useEffect(() => {
    setShowCancellationReason(outcome === 'customer_cancellation' || outcome === 'hybrid_ineligible')
  }, [outcome])

  const validate = () => {
    const errors = {}

    const cancellationReason = cancellationReasonInput.current?.value

    if (!outcome) {
      errors.outcome = 'An order outcome is required'
    }

    if (showCancellationReason && (cancellationReason || '') === '') {
      errors.cancellationReason = 'A cancellation reason is required'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    updateOrderRequest({
      id: orderId,
      outcome,
      cancellationReason: showCancellationReason ? cancellationReasonInput.current?.value : '',
    })
      .then(() => window.location.reload())
      .catch(handleApiErrors)
  }

  const handleApiErrors = response => {
    const errors = get(response, `responseJSON.errors.fast-track-order_${orderId}`)

    if (errors) {
      setErrors(errors)
    }
  }

  return (
    <div className="m-box -inset">
      <div className='container__wai--jobs'>
        <h2 className="-size-xl m-t-0">Hybrid Installation Outcome</h2>

        <div className="o-form__row">
          <div className="m-form-field">
            <FrokSelect
              required={true}
              name='workCategory'
              placeholder='Select outcome'
              options={Object.keys(outcomes).map(key => ({ value: key, label: outcomes[key] }))}
              value={outcome}
              onChange={value => setOutcome(value)}
              error={errors.outcome}
            />
          </div>
        </div>

        {showCancellationReason && (
          <>
            <div className='form-group--comments' style={{ position: 'relative', marginTop: '30px' }}>
              <div className="a-text-area">
                <label htmlFor="cancellation-reason">Cancellation Reason</label>
                <textarea
                  id="cancellation-reason"
                  placeholder='Enter cancellation details here …'
                  rows='4'
                  cols='50'
                  ref={cancellationReasonInput}
                  defaultValue={cancellationReason}
                />
              </div>
              {'cancellationReason' in errors && (
                <FrokNotification type="-error" noIcon>{errors.cancellationReason}</FrokNotification>
              )}
            </div>
          </>
        )}

        <FrokBtnLoader className='a-button a-button--primary -without-icon' style={{ marginTop: '30px' }} onClick={handleSubmit}>
          Save outcome
        </FrokBtnLoader>
      </div>
    </div>
  )
}

FastTrackOrderOutcome.propTypes = {
  orderId: PropTypes.string,
  outcomes: PropTypes.object,
  orderOutcome: PropTypes.shape({
    outcome: PropTypes.string,
  }),
  cancellationReason: PropTypes.string,
}

export default FastTrackOrderOutcome
