import React, { Component } from 'react'
import uploadFileRequest from '../../shared/network/requests/uploadFileRequest'
import updateOrderRequest from '../../fast-track/network/requests/updateOrderRequest'
import classNames from 'classnames'
import { merge, chain, identity } from 'lodash'
import FrokBtnLoader from '../../shared/components/FrokBtnLoader'
import FrokFilePicker from '../../shared/components/FrokFilePicker'
import { extensions } from '../../guarantee/constants'
import FrokNotification from '../../shared/components/FrokNotification'

class FastTrackInstallerImageUpload extends Component {
  boilerImages = {
    inside: null,
    outside: null,
    pipework: null,
    meter: null,
  }

  hybridImages = {
    'heat-loss': null,
    'outdoor-unit': null,
    'indoor-unit': null,
    additional: null,
  }

  optionalImages = [
    'additional'
  ]

  state = {
    errors: {},
    loading: false,
    images: {},
  }

  componentDidMount () {
    const { isHybridOrder, outcome } = this.props

    this.setState({ images: this.boilerImages })

    if (isHybridOrder && outcome === 'heat_pump_installed') {
      this.setState({ images: merge(this.boilerImages, this.hybridImages) })
    }
  }

  showImageInput (key) {
    return key in this.state.images
  }

  isImageRequired (key) {
    return !this.optionalImages.includes(key)
  }

  validate () {
    const { images } = this.state
    const errors = {}
    const keys = Object.keys(images)
    const img = Object.values(images)
    for (let key of keys) {
      const image = images[key]
      if (!image && this.isImageRequired(key)) {
        errors[key] = 'Please select an image'
      } else {
        const same = img.filter(i => isSameFile(i, image))
        if (same.length > 1) { errors[key] = 'Image must be unique' }
      }
    }

    this.setState({ errors })

    return Object.keys(errors).length > 0
  }

  handleClick = () => {
    const errors = this.validate()
    if (errors) return
    const { images } = this.state
    const ftOrder = {
      id: this.props.orderId
    }
    this.setState({ loading: true })
    const keys = chain(images).pickBy(identity).keys().value()
    return Promise.all(keys.map(key => uploadFileRequest(images[key])))
      .then(responses => {
        // loop over the return uploaded images and add the location to the object
        responses.forEach((response, index) => (response.location = keys[index]))
        // update the express order
        updateOrderRequest({
          ...ftOrder,
          'installer-evidence': responses
        })
          .then(() => {
            window.location.reload()
          })
      })
      .catch(error => {
        const errorMessage = error.responseJSON.errors.file[0]
        this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            general: `Error: ${errorMessage}`
          }
        })
      })
  }

  handleChange = (image, key) => {
    const file = new FileReader()
    file.onload = () => {
      const images = { ...this.state.images }
      images[key] = image
      this.setState({ images })
    }
    file.readAsDataURL(image)
  }

  handleError = (error, key) => {
    this.setState(state => ({
      errors: {
        ...state.errors,
        [key]: error
      }
    }))
  }

  renderFileInput (key, label) {
    if (!this.showImageInput(key)) {
      return
    }

    const { images, errors } = this.state
    return (
      <div
        key={key}
        className="tile__item"
      >
        <div className="m-form-field -half">
          <legend className='field__legend'>{label}</legend>
          <FrokFilePicker
            name={label}
            extensions={extensions}
            onChange={image => this.handleChange(image, key)}
            onError={error => this.handleError(error, key)}
            label="Select image"
            compact
            preview={images[key] ? URL.createObjectURL(images[key]) : null}
          />
          {key in errors && <FrokNotification type="-error" noIcon>{errors[key]}</FrokNotification>}
        </div>
      </div>
    )
  }

  render () {
    const { loading, errors } = this.state
    const { description } = this.props
    const error = errors.general

    return (
      <div className="band__fasttrack--current">
        <div className="container__wai--jobs">
          <h3 className="-size-xl m-t-0 table__header--light">Upload proof photos</h3>
          <div className="text-center">
            <div dangerouslySetInnerHTML={{__html: description}} />
          </div>
          <div className="tile__fasttrack--proof">
            <p className="m-t-0">
              Accepted formats: JPG, JPEG, PNG.
              <br />
              Maximum file size: 5MB
            </p>

            {this.renderFileInput('inside', 'Photo of boiler inside house')}
            {this.renderFileInput('outside', 'Photo of flue outside house')}
            {this.renderFileInput('pipework', 'Photo of boiler pipework inside house')}
            {this.renderFileInput('meter', 'Photo of the gas meter')}

            {this.renderFileInput('heat-loss', 'Evidence of heat loss calculation')}
            {this.renderFileInput('outdoor-unit', 'Photo of the outdoor unit')}
            {this.renderFileInput('indoor-unit', 'Photo of the indoor unit')}
            {this.renderFileInput('additional', 'Any additional photos')}
          </div>
          <div className={classNames({ 'has-error': error })}>
            <span className={'pull-left help-block'}>{error}</span>
          </div>
        </div>
        <div className="text-center margin-top">
          <FrokBtnLoader
            altColor={true}
            loading={loading}
            onClick={this.handleClick}
            className='a-button a-button--primary -without-icon'
          >
            Upload
          </FrokBtnLoader>
        </div>
      </div>
    )
  }
}

function isSameFile (file1, file2) {
  if (!file1 || !file2) return false
  return (
    file1.name === file2.name &&
    file1.size === file2.size &&
    file1.lastModified === file2.lastModified
  )
}
export default FastTrackInstallerImageUpload
