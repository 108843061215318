import React, { useState, useMemo } from 'react'
import PasswordModal from './PasswordModal'
import createSchema from './createSchema'
import submitAccordionForm from '../../network/requests/submitAccordionForm'
import transformError from '../../../fast-track/validation/transformError'
import { getPasswordError, getUserFriendlyErrorMessage, getValidationErrorMap } from '../../util/getErrors'
import FormRow from '../../../shared/components/FormRow/FormRow'
import flattenFields from '../../../shared/util/flattenFields'

export default function AccordionForm ({ form: { fields, name, requiresPassword } }) {
  const initialState = useMemo(() => flattenFields(fields), [fields])
  const schema = useMemo(() => createSchema(fields), [fields])
  const [editable, setEditable] = useState(false)
  const [values, setValues] = useState(initialState)
  const [fieldErrors, setFieldErrors] = useState({})
  const [remoteError, setRemoteError] = useState(false)
  const [requestPassword, setRequestPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const makeRequest = () => {
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {
        if (valid) {
          setFieldErrors({})
          validatePassword()
        }
      })
      .catch(error => {
        const errors = transformError(error)
        // console.warn(error, errors)
        setFieldErrors(errors)
      })
  }

  const validatePassword = () => {
    if (requiresPassword) {
      setRequestPassword(true)
    } else {
      postData(values)
    }
  }

  const postData = data => {
    setLoading(true)
    setRemoteError(false)
    submitAccordionForm(name, data)
      .then(() => {
        setRequestPassword(false)
        setEditable(false)
      })
      .catch(err => {
        const passwordError = getPasswordError(err)
        if (requiresPassword && passwordError) {
          setRemoteError(passwordError)
          setRequestPassword(true)
        } else {
          const errorMap = getValidationErrorMap(err)
          if (errorMap) {
            setFieldErrors(errorMap)
          } else {
            const message = getUserFriendlyErrorMessage(err)
            setRemoteError(message)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleToggleEdit = () => {
    if (editable) {
      // reset to initial state
      setValues(initialState)
    }
    setEditable(!editable)
  }

  const handleOnChange = (name, value) => setValues({ ...values, [name]: value })

  return (
    <>
      <button className="a-button a-button--secondary -without-icon" onClick={handleToggleEdit}><span className="a-button__label">{editable ? 'Cancel' : 'Edit details'}</span></button>
      <table className="m-table">
        {fields.map(child => (
          <FormRow
            key={child.name}
            {...child}
            error={fieldErrors[child.name]}
            editable={editable}
            onChange={handleOnChange}
            value={values[child.name]}
          />
        ))}
      </table>
      {!requestPassword && remoteError && <p className='error-text'>{remoteError}</p>}
      {editable && (
        <div className="a-accordion__form-save">
          <button onClick={makeRequest} className='a-button a-button--secondary -without-icon'>
            <span className="a-button__label">Save your changes</span>
          </button>
        </div>
      )}
      {requestPassword && (
        <PasswordModal
          onSubmit={password => postData({ ...values, currentPassword: password })}
          hide={() => setRequestPassword(false)}
          error={remoteError}
          loading={loading}
        />
      )}
    </>
  )
}
