import React, { Fragment, useEffect, useState } from 'react'
import OnboardingModal from './OnboardingModal'
import OnboardingSlide from './OnboardingSlide'
import {ajax} from '../../../shared/network/api'
import TermsAndConditionsSlide from './TermsAndConditionsSlide'

const slidesContent = {
  'welcome': [
    {
      title: 'Welcome to your Worcester Bosch loyalty portal!',
      body: 'It\'s time to get rewarded for your installations! Each time you install one of our award-winning products, you\'ll unlock access to fantastic benefits.',
      image: '/static/img/lifestyle/screen-1.jpg'
    },
    {
      title: 'Register, Reward, Redeem',
      body: 'Start collecting points for your installations to spend on a range of items in the shop. We\'ve got workwear, merchandise, eVouchers and bags more in store! You can even convert points to your very own Worcester Bosch pre-paid Mastercard.',
      image: '/static/img/lifestyle/screen-2.jpg'
    },
    {
      title: 'Worcester Bosch Professional App',
      body: (<fragment>
        <p>Put the power of Worcester Bosch at your fingertips with easy access to product registrations, customers, plus an extensive knowledge base and local support.</p>
        <p>You can also view your loyalty points and your engineers can assign themselves to your account.</p>
      </fragment>),
      image: '/static/img/lifestyle/screen-3.jpg'
    },
    {
      title: 'Did you know?',
      body: 'You can access expert advice, from finance to marketing, based on exactly what you and your business needs.',
      image: '/static/img/lifestyle/screen-4.jpg'
    },
  ],
  'promotion': [
    {
      title: 'Congratulations! You\'ve been promoted to the next level',
      body: 'Take a look around at some of the new benefits in your portal.',
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'promotion-wi-l2': [
    {
      title: 'Congratulations! You’ve been promoted to a new level',
      body: 'Take a look around at some of the new benefits in your portal.',
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'needs-accreditation': [
    {
      title: 'Congratulations! You’ve been promoted to a Worcester Bosch Accredited Installer.',
      body: (<fragment>
        <p>In order to unlock your new benefits with us, you need to attend your accreditation training.</p>
        <p>Call our training team on 0330 123 0166 and quote code <strong>WAI</strong> to book on the next available date.</p>
      </fragment>),
      image: '/static/img/excelerate/promotion.png',
    },
  ],
  'promotion-wap': [
    {
      title: 'Congratulations! You\'ve been promoted to a Worcester Bosch Accredited Partner.',
      body: 'Take a look around at some of the new benefits in your portal.',
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'promotion-wap-l2': [
    {
      title: 'Congratulations! You\'ve been promoted to the next level.',
      body: 'Take a look around at some of the new benefits in your portal.',
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'demotion': [
    {
      title: 'Welcome to your loyalty portal!',
      body: (<Fragment>
        <p>Your account has now been updated to your latest level.</p>
        <p>All of your historic sales leads and guarantee registrations are still here for you to view.</p>
        <p>Keep registering your products for the chance to move up to your next level.</p>
      </Fragment>),
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'demotion-wai-wi': [
    {
      title: 'Welcome to your loyalty portal!',
      body: (<Fragment>
        <p>Your account has now been updated to your latest level.</p>
        <p>All of your historic sales leads and guarantee registrations are still here for you to view.</p>
        <p>Keep registering your products for the chance to move up to your next level.</p>
      </Fragment>),
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'demotion-wai': [
    {
      title: 'Welcome to your loyalty portal!',
      body: (<Fragment>
        <p>Your account has now been updated to your latest level.</p>
        <p>All of your historic sales leads and guarantee registrations are still here for you to view.</p>
        <p>Keep registering your products for the chance to move up to your next level.</p>
      </Fragment>),
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'demotion-wap-wai': [
    {
      title: 'Welcome to your loyalty portal!',
      body: (<Fragment>
        <p>Your account has now been updated to your latest level.</p>
        <p>All of your historic sales leads and guarantee registrations are still here for you to view.</p>
        <p>Keep registering your products for the chance to move up to your next level.</p>
      </Fragment>),
      image: '/static/img/excelerate/promotion.png'
    },
  ],
  'demotion-wap': [
    {
      title: 'Welcome to your loyalty portal!',
      body: (<Fragment>
        <p>Your account has now been updated to your latest level.</p>
        <p>All of your historic sales leads and guarantee registrations are still here for you to view.</p>
        <p>Keep registering your products for the chance to move up to your next level.</p>
      </Fragment>),
      image: '/static/img/excelerate/promotion.png'
    },
  ]
}
/**
 * Loyalty onboarding slides
 */
export default function LoyaltyOnboarding ({ type, showTerms, termsVersion }) {
  const slides = slidesContent[type]
  const [name, setName] = useState('')
  const [errors, setErrors] = useState({})
  const [terms, setTerms] = useState([])
  const [acceptedTerms, setAcceptedTerms] = useState({})

  useEffect(() => {
    ajax('/loyalty-terms', {
      method: 'GET',
    }).then(data => {
      setTerms(data)
      let accepted = {}
      data.forEach(item => {
        accepted[item.id] = false
      })
      setAcceptedTerms(accepted)
    })
  }, [])

  const onComplete = () => {
    ajax('/onboarding/' + type + '/complete', {
      method: 'POST',
    })
  }

  const declineTerms = () => {
    ajax('/loyalty-terms/decline', {
      method: 'POST',
    })
  }

  const acceptTerms = () => {
    ajax('/loyalty-terms/accept', {
      method: 'POST',
      data: {
        name,
        termIds: Object.keys(acceptedTerms)
      }
    }).then(() => {
      window.location.href = '/account'
    })
  }

  return (<OnboardingModal
    onComplete={onComplete}
    acceptTerms={acceptTerms}
    declineTerms={declineTerms}
    termsVersion={termsVersion}
    terms={terms}
    setTerms={setTerms}
    acceptedTerms={acceptedTerms}
    setAcceptedTerms={setAcceptedTerms}
    errors={errors}
    setErrors={setErrors}
    name={name}
  >
    {slides.map(content => {
      return <OnboardingSlide {...content} key={content.title} />
    })}
    {Boolean(showTerms) && (
      <TermsAndConditionsSlide
        isTerms={true}
        terms={terms}
        setTerms={setTerms}
        acceptedTerms={acceptedTerms}
        setAcceptedTerms={setAcceptedTerms}
        setName={setName}
        key='terms'
        errors={errors}
      />
    )}
  </OnboardingModal>)
}
