import React from 'react'

export const LoadingSpinner = () => (
  <tr>
    <td colSpan="4" className="no-effects">
      <div className="loader"></div>
      <p className="text-center">Loading Guarantees...</p>
    </td>
  </tr>
)

export const ErrorMessage = ({ message }) => (
  <tr>
    <td colSpan="4">
      <p className="text-center">
        Error: {message}
      </p>
    </td>
  </tr>
)

export const EmptyResult = () => (
  <tr>
    <td colSpan="4">
      <p className="text-center">No registrations found</p>
    </td>
  </tr>
)
