import uuid from 'uuid/v4'
import { pick } from 'lodash'

const preferencesFields = [
  'supportedProducts',
  'preferredProducts',
  'distance',
  'expressService',
  'weekends',
  'weekendSalesLeads'
]

// convert the flat form data into a shape the presenter will recognise
export default function preferencesFormatter (data) {
  return {
    id: uuid(),
    preferences: {
      id: uuid(),
      ...pick(data, preferencesFields),
    }
  }
}
